import { createAction, createReducer } from 'redux-act';
import { combineReducers } from 'redux';
import {
  StatusPayload,
  AddAppealRequestPayload,
  GetAppealListPayload,
  AppealListState,
  MessagesState,
  AppealDetailState,
  AddAppealSuccessPayload,
  APPEAL_STEP,
  SupportTicketCalculationState,
  SupportTicketCalculationDto,
  AppealHistoryState,
  GetRefundAmountDTO,
} from './types/types';
import {
  AppealListInitialState,
  AppealDetailInitialState,
  addAppealInitialState,
} from './constants';
import { AppealsListNextAction } from '.';
import {
  OrderRefundAmountInitialState,
  OrderRefunded,
} from '@modules/orders/constants';
import { OrderRefundAmountPositions } from '../orders/constants';
import {
  OrderRefundAmountPositionsType,
  OrderRefundedType,
} from '@modules/orders';

export const appealModalHandler = createAction('@appeals/appealModalHandler');

export const setAppealModal = createAction<boolean>('@appeals/setAppealModal');

export const setLoadingStatus = createAction<StatusPayload>(
  '@appeals/setLoadingStatus',
);
export const setMessageStatus = createAction<StatusPayload>(
  '@appeals/setMessageStatus',
);

export const setMessageList = createAction<MessagesState>(
  '@appeals/setMessageList',
);

export const sendMessage = createAction<{ message: string; files: File[] }>(
  '@appeals/sendMessage',
);

export const sendMessageSuccess = createAction<boolean>(
  '@appeals/sendMessageSuccess',
);

export const getDetail = createAction<number>('@appeals/getDetail');
export const getList = createAction<GetAppealListPayload>('@appeals/getList');
export const setList = createAction<AppealListState>('@appeals/setList');
export const getAppealsListNext = createAction<AppealsListNextAction>(
  '@appeals/getAppealsListNext',
);

export const setDetail = createAction<AppealDetailState>('@appeals/setDetail');
export const detailPurify = createAction('@appeals/detailPurify');

export const getRefundAmount = createAction<{ orderPositionId: string }>(
  '@appeal/getRefundAmount',
);
export const getRefundAmountSuccess = createAction<GetRefundAmountDTO>(
  '@appeal/getRefundAmountSuccess',
);
export const getRefundAmountError = createAction<{ error: string }>(
  '@appeal/getRefundAmountError',
);
export const setRefunded = createAction<OrderRefundedType>(
  '@appeal/getRefundAmount',
);

export const addAppealRequest = createAction<AddAppealRequestPayload>(
  '@appeals/addAppealRequest',
);
export const addAppealSuccess = createAction<AddAppealSuccessPayload | null>(
  '@appeals/addAppealSuccess',
);
export const addAppealRefund = createAction('@appeals/addAppealRefund');
export const addRefundReason = createAction<{
  value: string;
  code: APPEAL_STEP;
}>('@appeals/addRefundReason');
export const setRefundStep = createAction<APPEAL_STEP>(
  '@appeals/setRefundStep',
);
export const setRefundPositions = createAction<OrderRefundAmountPositionsType>(
  '@appeals/setRefundPositions',
);
export const addAppealFailure = createAction('@appeals/addAppealFailure');

export const addAppealPurify = createAction('@appeal/getAppealPurify');

export const getCalculationByTicketNumberRequest = createAction<number>(
  '@appeals/getCalculationByTicketNumberRequest',
);
export const getCalculationByTicketNumberSuccess =
  createAction<SupportTicketCalculationDto>(
    '@appeals/getCalculationByTicketNumberSuccess',
  );
export const getCalculationByTicketNumberFailure = createAction(
  '@appeals/getCalculationByTicketNumberFailure',
);
export const supportTicketCalculationPurify = createAction(
  '@appeals/supportTicketCalculationPurify',
);

export const getHistoryRequest = createAction('@appeals/getHistoryRequest');
export const getHistorySuccess = createAction<AppealHistoryState>(
  '@appeals/getHistorySuccess',
);

export const cancelAppealRequest = createAction<string>(
  '@appeals/cancelAppealRequest',
);
export const cancelAppealSuccess = createAction('@appeals/cancelAppealRequest');

const history = createReducer<AppealHistoryState>({}, []);
history.on(getHistorySuccess, (_, payload) => payload);

const status = createReducer({}, null as StatusPayload);
status.on(setLoadingStatus, (_, payload) => payload);

const messageStatus = createReducer({}, null as StatusPayload);
status.on(setMessageStatus, (_, payload) => payload);

const list = createReducer({}, AppealListInitialState);
list.on(setList, (_, payload) => ({ ...payload, isLoading: false }));
list.on(getAppealsListNext, (state) => ({ ...state, isLoading: true }));

const messagesList = createReducer<MessagesState>({}, []);
messagesList.on(setMessageList, (_, payload) => payload);
messagesList.on(detailPurify, () => []);

const detail = createReducer({}, AppealDetailInitialState);
detail.on(setDetail, (_, payload) => payload);
detail.on(detailPurify, () => AppealDetailInitialState);

const refundAmount = createReducer({}, OrderRefundAmountInitialState);

refundAmount.on(getRefundAmount, (state) => {
  return {
    ...state,
    isLoading: true,
    error: false,
  };
});
refundAmount.on(getRefundAmountSuccess, (state, payload) => ({
  ...state,
  data: payload,
  isLoading: false,
}));
refundAmount.on(getRefundAmountError, (state) => ({
  ...state,
  error: true,
  isLoading: false,
}));

const refundPositions = createReducer({}, OrderRefundAmountPositions);
refundPositions.on(setRefundPositions, (_, payload) => payload);

const refunded = createReducer({}, OrderRefunded);
refunded.on(setRefunded, (_, payload) => payload);

const messages = combineReducers({
  items: messagesList,
  status: messageStatus,
});

const addAppeal = createReducer({}, addAppealInitialState);
addAppeal.on(addAppealRequest, (state) => ({
  ...state,
  STEP: APPEAL_STEP.REQUEST,
}));
addAppeal.on(addAppealSuccess, (_, payload) => ({
  STEP: APPEAL_STEP.SUCCESS,
  response: payload,
}));
addAppeal.on(addAppealFailure, () => ({
  STEP: APPEAL_STEP.FAILURE,
  response: null,
}));
addAppeal.on(addAppealRefund, () => ({
  STEP: APPEAL_STEP.REFUND,
  response: null,
}));
addAppeal.on(setRefundStep, (_, payload) => ({
  STEP: payload,
  response: null,
}));
addAppeal.on(addRefundReason, (_, payload) => {
  const { value, code } = payload;
  const currentURL = window.location.pathname;
  const url = `${currentURL}?reason=${encodeURIComponent(value)}`;
  window.history.pushState(null, '', url);

  return {
    STEP: code,
    response: null,
  };
});
addAppeal.on(addAppealPurify, () => {
  clearUrlSearch();
  return addAppealInitialState;
});

const clearUrlSearch = () => {
  const url = new URL(window.location.href);
  url.search = '';
  window.history.pushState(null, '', url.href);
};

const supportTicketCalculation = createReducer<SupportTicketCalculationState>(
  {},
  null,
);

supportTicketCalculation.on(
  getCalculationByTicketNumberSuccess,
  (_, payload) => payload,
);

supportTicketCalculation.on(supportTicketCalculationPurify, () => null);

const chatLoadings = createReducer<boolean>({}, false);
chatLoadings.on(sendMessage, () => true);
chatLoadings.on(sendMessageSuccess, () => false);

const appealModalState = createReducer<boolean>({}, false);
appealModalState.on(setAppealModal, (_, payload) => payload);

export default combineReducers({
  status,
  messages,
  list,
  detail,
  addAppeal,
  refundAmount,
  refundPositions,
  refunded,
  supportTicketCalculation,
  history,
  chatLoadings,
  appealModalState,
});
