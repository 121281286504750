import React from 'react';
import styled from 'styled-components';
import { Title, GrayText, Paragraph } from './shared';
import { Button } from '@components/ui';
import { NavLink, useHistory } from 'react-router-dom';
import { ReactComponent as ArrowIcon } from '@assets/media/main-page/telegram-link-arrow.svg';
import { ReactComponent as BackgroundSVG1 } from '@assets/media/main-page/loyalityBackground1.svg';
import { ReactComponent as BackgroundSVG2 } from '@assets/media/main-page/loyalityBackground2.svg';

interface Props {
  type: 'train' | 'avia' | 'bus';
}

const Wrapper = styled.div`
  padding: 45px;
  background: #feedff;
  border-radius: 16px;
  position: relative;

  @media (max-width: 767px) {
    padding: 30px 20px;
  }
`;

const ModalButton = styled(Button).attrs({
  type: 'primary',
  htmlType: 'button',
})`
  padding: 11px 20px;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;

  & > svg {
    margin-right: 5px;
  }

  @media (max-width: 767px) {
    padding: 8px 20px;
    width: 100%;
  }
`;

const Content = styled.div`
  position: relative;
  z-index: 2;

  ${Title} {
    display: block;
    margin-bottom: 24px;
  }

  ${GrayText} {
    display: block;
    margin-bottom: 8px;
  }

  ${Paragraph} {
    display: block;
    margin-bottom: 20px;
  }

  ${ModalButton} {
    margin-top: 36px;
  }
`;

const BackgroundRight = styled(BackgroundSVG1)`
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 1;

  @media (max-width: 767px) {
    display: none;
  }
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    opacity: 0.4;
  }
`;

const BackgroundLeft = styled(BackgroundSVG2)<LinkProps>`
  position: absolute;
  left: ${({ left }) => (left ? left : 'auto')};
  bottom: ${({ bottom }) => (bottom ? bottom : 'auto')};
  z-index: 1;
  right: ${({ right }) => (right ? right : 'auto')};
  top: ${({ top }) => (top ? top : 'auto')};

  width: ${({ width }) => width};
  height: ${({ height }) => height};
  transform: ${({ transform }) => (transform ? transform : 'rotate(0deg)')};

  @media (max-width: 767px) {
    display: none;
  }
`;

interface LinkProps {
  width: string;
  height: string;
  left?: string;
  bottom?: string;
  top?: string;
  right?: string;
  transform?: string;
}

export default function LoyalityProgramBanner(props: Props) {
  const history = useHistory();

  return (
    <Wrapper>
      <BackgroundLeft
        width={'35px'}
        height={'35px'}
        right={'100px'}
        bottom={'200px'}
      />
      <BackgroundLeft
        width={'55px'}
        height={'55px'}
        right={'30px'}
        bottom={'150px'}
        transform={'rotate(45deg)'}
      />
      <BackgroundLeft
        width={'55px'}
        height={'75px'}
        right={'300px'}
        bottom={'20px'}
        transform={'rotate(55deg)'}
      />
      <BackgroundLeft
        width={'105px'}
        height={'105px'}
        left={'50px'}
        bottom={'60px'}
        transform={'rotate(310deg)'}
      />

      <BackgroundRight />
      <Content>
        <GrayText>Участвуйте в программе лояльности</GrayText>
        <Title> Вернем до 4% от стоимости</Title>
        <>
          <Paragraph>
            Забронируйте билет на FUN&SUN и получите бонусные баллы для оплаты
            следующих поездок.
          </Paragraph>
        </>
        <Paragraph>
          <span style={{ fontWeight: 'bold' }}>
            Присоединяйтесь к FUN&SUN FRIENDS!
          </span>
        </Paragraph>
        <Paragraph>
          <a
            href="https://fstravel.com/pravila-ucastiya-v-programme-loyalnosti-funsun-friends"
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: 'none', color: '#4872F2' }}
          >
            Подробнее о программе лояльности
          </a>
        </Paragraph>
        {/* <ModalButton onClick={() => history.push('/questions/how-to-create-support-ticket')}><ButtonIcon /> Служба поддержки по заказу</ModalButton> */}
      </Content>
    </Wrapper>
  );
}
