import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { TrainRoute } from '@modules/trainTickets/dto/GetTrainRouteDto';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';

interface Props {
  routeDetails: TrainRoute[]; // Данные о маршруте, полученные от сервера

  segmentType?: 'base' | 'additional'; // Тип сегмента ЖД пути - основной или дополнительный (для беспересадочных маршрутов)
  title: string; // Заголовок панели маршрута
  fromStationCode: string; // Код станции отправления
  toStationCode: string; // Код станции прибытия
}

const Wrapper = styled.div``;

const Header = styled.div`
  padding: 10px 20px;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  z-index: 4;
  @media (max-width: 767px) {
    padding: 15px 20px 5px 20px;
  }
`;

const Table = styled.table`
  width: 100%;
  padding: 4px 20px 0 20px;

  @media (max-width: 767px) {
    font-size: 12px;
  }
`;

const TableCell = styled.td<{ align: 'left' | 'right' | 'center' }>`
  padding-bottom: 10px;
  text-align: ${({ align }) => align};
`;

const TableCellContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Point = styled.div`
  width: 8px;
  height: 8px;
  background-color: #4872f2;
  border-radius: 50%;
  margin-right: 8px;
  position: relative;
  z-index: 2;
`;

const PointFromToStation = styled(Point)`
  border: 4px solid #e3eaff;
  position: relative;
  left: -4px;
  margin-right: 0;
`;

const Line = styled.div`
  background-color: #e3eaff;
  width: 2px;
  height: 46px;
  left: 3px;
  position: absolute;
  z-index: 1;
`;

const PointContainer = styled.div`
  position: relative;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;

    @media (max-width: 767px) {
        font-size: 12px;
    }
`;

const Content = styled.div``;

export default function BusRoutePanel(props: Props) {
  const { title, routeDetails, fromStationCode, toStationCode, segmentType } =
    props;

  if (!routeDetails?.length) return null;

  const [indexes, setIndexes] = useState<number[]>([]);
  const [expanded, setExpanded] = useState(true);
  const [fromIndex, setFromIndex] = useState<number>(0);
  const [toIndex, setToIndex] = useState<number>(0);
  const isMobile = useMediaQuery({ maxWidth: 767 });

  useEffect(() => {
    const list = routeDetails?.map((route) => route.station.code);
    const fromIndex = 0;
    const toIndex = list?.length - 1;
    setFromIndex(fromIndex);
    setToIndex(toIndex);
  }, [routeDetails]);

  useEffect(() => {
    const indexes: number[] = [];
    if (routeDetails?.length > 0) {
      if (expanded) {
        routeDetails.forEach((route, index) => {
          indexes.push(index);
        });
      } else {
        routeDetails.forEach((route, index) => {
          if (index >= fromIndex && index <= toIndex) {
            indexes.push(index);
          }
        });
      }
    }
    setIndexes(indexes);
  }, [routeDetails, expanded, fromIndex, toIndex]);

  const firstStation = routeDetails[0];
  const lastStation = routeDetails[routeDetails.length - 1];
  const departureDateTime = routeDetails[0]?.departureDateTime;
  const arrivalDateTime =
    routeDetails[routeDetails.length - 1]?.arrivalDateTime;

  return (
    <Wrapper>
      <Header>
        <TitleContainer>
          <span>
            {`${moment(departureDateTime).format('DD.MM.YYYY, HH:mm')} ${firstStation?.station.name_ru} —`}
          </span>
          <span>
            {`${moment(arrivalDateTime).format('DD.MM.YYYY, HH:mm')} ${lastStation?.station.name_ru}`}
          </span>
        </TitleContainer>
      </Header>
      <Content>
        <Table>
          {routeDetails.length > 0 ? (
            routeDetails.map((route, index) => {
              if (!indexes.includes(index)) return false;
              const needLine = expanded
                ? index !== routeDetails.length - 1
                : index !== toIndex;
              return (
                <tr key={index}>
                  <TableCell align={'left'}>
                    <TableCellContainer>
                      {index < fromIndex || index > toIndex ? (
                        <PointContainer>
                          {needLine ? <Line /> : ''}
                          <Point />
                        </PointContainer>
                      ) : fromStationCode === route.station.code ||
                        toStationCode === route.station.code ? (
                        <PointContainer>
                          {needLine ? <Line /> : ''}
                          <PointFromToStation />
                        </PointContainer>
                      ) : (
                        <PointContainer>
                          {needLine ? <Line /> : ''}
                          <Point />
                        </PointContainer>
                      )}
                      {route.station.name_ru}
                    </TableCellContainer>
                  </TableCell>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={4}>Загрузка маршрута...</td>
            </tr>
          )}
        </Table>
      </Content>
    </Wrapper>
  );
}
