import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import Popup from 'reactjs-popup';
import { ReactComponent as FunSunLogo } from '@assets/media/shared/funSunLogo.svg';
import smallNofification from '@assets/media/shared/notification.svg';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { stickyFooterHide } from '@modules/orders';

const StyledPopup = styled(Popup)`
  &-overlay {
    @media (max-width: 767px) {
      z-index: 1000 !important;
      overflow: scroll;
    }
  }

  &-content {
    width: 610px;
    border: 1px solid #dcdcdc;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 30px;
    border-radius: 8px;
    box-sizing: border-box;
    position: relative;
    @media (max-width: 767px) {
      width: 100%;
      height: 100%;
      padding: 20px 10px 20px 20px;
      box-sizing: border-box;
      border-radius: 0;
    }
  }
`;

const PopupHead = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
`;

const Cross = styled.a`
  width: 15px;
  height: 15px;
  cursor: pointer;
  position: absolute;
  right: 25px;
  top: 26px;
  outline: none;

  @media (max-width: 767px) {
    right: 25px;
    top: 25px;
  }

  &:hover {
    &:before,
    &:after {
      background-color: ${({ theme: { colors } }) => colors.darkGray};
    }
  }

  &:before,
  &:after {
    position: absolute;
    content: ' ';
    height: 17px;
    width: 2px;
    right: 3.5px;
    bottom: 0.5px;
    background-color: ${({ theme: { colors } }) => colors.main};
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
`;

const Text = styled.div`
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #3c3c3c;
  margin-top: 15px;
`;

const BlockText = styled(Text)`
  display: block;
`;

const TitleText = styled(BlockText)`
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin-left: 8px;
  margin-bottom: 10px;
  @media (max-width: 767px) {
    font-size: 18px;
    line-height: 18px;
  }
`;

interface Props {
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  isModalOpen: boolean;
}

const LoyalityModal: React.FC<Props> = ({ isModalOpen, setIsModalOpen }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <StyledPopup
      open={isModalOpen}
      onClose={() => {
        setIsModalOpen(false);
        dispatch(stickyFooterHide(false));
      }}
    >
      <Cross
        onClick={() => {
          setIsModalOpen(false);
          dispatch(stickyFooterHide(false));
        }}
      />
      <PopupHead>
        <Title>
          <FunSunLogo />
          <TitleText>Программа лояльности</TitleText>
        </Title>
      </PopupHead>

      <Text>
        Авторизованным участникам Программы лояльности FUN&SUN Friends
        участникам начисляется до 4% фанами за покупку билета.
      </Text>
      <Text>
        Чтобы узнать свой уровень, посетите личный кабинет
        https://fstravel.com/cabinet/bonuses
      </Text>
      <Text>
        Накопленными фанами можно оплатить до 50% стоимости отелей, круизов и
        туров.
      </Text>
      <Text>
        Потратить фаны можно при покупке в фирменных офисах, на сайте
        https://fstravel.com и в мобильном приложении FUN&SUN
      </Text>
      <Text>При отмене заказа, начисленные фаны также отменяются.</Text>
    </StyledPopup>
  );
};

export default LoyalityModal;
