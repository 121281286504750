import React, { useState } from 'react';
import styled from 'styled-components';
import { Helper } from '@utils';
import Text from '@components/ui/Typography';
import { Point } from '@components/common/FlightSegment';
import { useMediaQuery } from 'react-responsive';
import flightNumberIcon from '@assets/media/flight-search/flightNumberIcon.svg';
import moment from 'moment';
import PlaceBus from './PlaceBus';
import Chip from '@components/ui/Chip/Chip';
import { Text as BaseText } from '@components/ui';
import RailwayOrBusDetailRoutesContainer from '@components/common/RouteDetail/RailwayOrBusDetailRoutesContainer';
import Popup from 'reactjs-popup';
import { useSelector } from 'react-redux';
import { busRouteDetailsSelector } from '@modules/busTickets/selectors';
import { Bus } from '@modules/busTickets';
import { transformRaceToTrainRouteDto } from '@modules/busTickets/normalizers';

const BusRouteText = styled(BaseText)`
  &:hover {
    cursor: pointer;
  }
`;

const StyledPopup = styled(Popup)`
  &-content {
    max-width: 600px;
    padding: 0;
  }

  @media (max-width: 767px) {
    &-content {
      width: auto;
    }
  }
`;

const Time = styled(Text)`
  font-size: 24px;
  line-height: 32px;
  display: inline-block;

  @media (max-width: 767px) {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
  }
`;

const ItemContainer = styled.li`
  list-style-type: none;
  position: relative;
  font-family: ${({ theme: { fonts } }) => fonts.regular};
  @media screen and (max-width: 768px) {
    padding: 0 14px;
  }
`;

const FlightTime = styled.span<{ blue?: boolean }>`
  display: inline;
  font-size: 12px;
  line-height: 16px;
  color: ${({ blue, theme: { colors } }) => (blue ? colors.main : '#737373')};

  @media (max-width: 767px) {
    display: none;
  }
`;

const ProgressInfo = styled.div`
  text-align: center;
  width: 100%;
  margin: 0 10px;
  span {
    margin-bottom: 7px;
  }
`;

const ProgressLine = styled.div`
  height: 2px;
  background-color: #dcdcdc;
  position: relative;
  & > div {
    top: -8px;
  }

  & > :last-child {
    right: 0;
  }

  @media (max-width: 767px) {
    & > div {
      top: -6px;
    }
  }
`;

const PlaceTimeWrapper = styled.div`
  display: flex;
  margin-bottom: 9px;
`;

const PlaceDateWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  & > :last-child {
    text-align: right;
  }
`;

const PointsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: absolute;
`;

const CompanyLogoMobile = styled.div`
  display: flex;
  align-items: center;
  margin-top: 12.5px;

  & > :first-child {
    margin-right: 5px;
  }

  @media (min-width: 768px) {
    display: none;
  }
`;

const LogoDescription = styled(Text)`
  font-size: 12px;
  line-height: 16px;
  color: #3c3c3c;
`;

const Image = styled.img.attrs({ width: 21.45, height: 21.45 })`
  border-radius: 50%;
  background: #9097ce;
`;

const PathTime = styled(Text)`
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: #737373;
  position: absolute;
  right: 50%;
  transform: translateX(50%);
  bottom: 3px;

  @media (min-width: 768px) {
    display: none;
  }
`;

const FlightNumberIcon = styled.img.attrs({ src: flightNumberIcon })`
  margin-right: 7px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

const TimeText = styled.div`
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: dark-gray;
`;

interface Props {
  bus: Bus;
  from: string;
  to: string;
  fromDate: string;
  toDate: string;
  duration: number;
  isFastest: string;
  isCheapest: string;
  getRoute?: () => void; // Получить маршрут
}

const MainSegmentBus: React.FC<Props> = ({
  from,
  to,
  fromDate,
  toDate,
  duration,
  isFastest,
  isCheapest,
  getRoute,
  bus,
}) => {
  const busRaceDetails = useSelector(busRouteDetailsSelector);
  const [busRouteState, setBusRouteState] = useState(false);

  const isMobile = useMediaQuery({ maxWidth: 767 });

  const travelTime = Helper.formatDuration(duration);

  const handleGetRoute = () => {
    getRoute?.();
    setBusRouteState(true);
  };

  return (
    <div>
      <ItemContainer>
        <PlaceTimeWrapper>
          <Time color="#3C3C3C" bold>
            {moment(fromDate).format('HH:mm')}
          </Time>
          <ProgressInfo>
            <Text size="small" color="dark-gray">
              {travelTime},&nbsp;
            </Text>
            <BusRouteText
              onClick={handleGetRoute}
              size={'small'}
              color={'blue'}
            >
              маршрут
            </BusRouteText>
            <ProgressLine>
              <PointsContainer>
                <Point />

                <Point />
              </PointsContainer>
            </ProgressLine>
          </ProgressInfo>
          <Time color="#3C3C3C" bold>
            {moment(toDate).format('HH:mm')}
          </Time>
        </PlaceTimeWrapper>
        <PlaceDateWrapper>
          <PlaceBus station={from} date={fromDate} />
          <PlaceBus station={to} date={toDate} />
        </PlaceDateWrapper>
        <Row>
          {isFastest && (
            <div style={{ width: 120 }}>
              {' '}
              <Chip bgColor={'green'} label={'Самый быстрый'} />
            </div>
          )}
          {isCheapest && (
            <div style={{ width: 120 }}>
              {' '}
              <Chip bgColor={'purple'} label={'Самый дешевый'} />
            </div>
          )}
        </Row>
      </ItemContainer>
      <StyledPopup
        open={busRouteState}
        onClose={() => {
          setBusRouteState(false);
        }}
      >
        <RailwayOrBusDetailRoutesContainer
          title={`Маршрут рейса № ${bus.raceNumber || 'нет данных'}`}
          routeType={'bus'}
          routeDetails={
            busRaceDetails
              ? transformRaceToTrainRouteDto(busRaceDetails)
              : undefined
          }
          fromStationCode={bus.fromStation.nameRu}
          toStationCode={bus.toStation.nameRu}
        />
      </StyledPopup>
    </div>
  );
};

export default MainSegmentBus;
