import {
  UserSignUpDto,
  UserSignUpBody,
  UserSimpleSignUpBody,
  UserSignInDto,
  GetAviaPassengersDto,
  PasswordChangeBody,
  UpdateUserPayload,
  GetUserDataDto,
  CreatePassengerBody,
  EditPassengerBody,
  RegisterCustomerLoyaltyTTEPayload,
} from './types';
import { Api } from '@utils';
import { ORDER_SERVICE_HOST, NEW_ORDER_SERVICE_HOST } from '@modules/constants';

const { CONTENT_TYPE } = Api;

const USER_URI = process.env.REACT_APP_USER_API as string;
const AVIA_PASSENGERS_URI = process.env.REACT_APP_PASSENGERS_API as string;
const PASSWORD_URI = process.env.REACT_APP_PASSWORD_API as string;

const ENDPOINTS = {
  signIn: process.env.REACT_APP_SIGN_IN as string,
  signInFunSun: process.env.REACT_APP_SIGN_IN_FUN_SUN as string,
  signInSamo: process.env.REACT_APP_SIGN_IN_SAMO as string,

  signUp: process.env.REACT_APP_SIGN_UP as string,
  simpleSignUp: process.env.REACT_APP_SIMPLE_SIGN_UP as string,
  passwordRecovery: process.env.REACT_APP_CHANGE_PASSWORD as string,
  getUserData: process.env.REACT_APP_GET_USER_DATA as string,
  updateUserData: process.env.REACT_APP_UPDATE_USER_DATA as string,
  getPassangers: process.env.REACT_APP_GET_FELLOW_TRAVELERS as string,
  editPassanger: process.env.REACT_APP_UPDATE_FELLOW_TRAVELER as string,
  createPassanger: process.env.REACT_APP_ADD_FELLOW_TRAVELER as string,
};

const EXTERNAL_ENDPOINTS = {
  signInTinkoff: process.env.REACT_APP_SIGN_IN_TINKOFF as string,
  signInTinkoffComplete: process.env
    .REACT_APP_SIGN_IN_TINKOFF_COMPLETE as string,
};

const createUserFetchUrl = Api.createFetchUrl(ORDER_SERVICE_HOST, USER_URI);
const createAviaPassengersFetchUrl = Api.createFetchUrl(
  ORDER_SERVICE_HOST,
  AVIA_PASSENGERS_URI,
);
const createPasswordFetchUrl = Api.createFetchUrl(
  ORDER_SERVICE_HOST,
  PASSWORD_URI,
);
const createExternalFetchUrl = Api.createFetchUrl(NEW_ORDER_SERVICE_HOST, '');

/**
 * action: @user/signIn
 * saga: signInSaga
 * @param login
 * @param password
 */
export function signIn(
  login: string,
  password: string,
  isAgent?: boolean,
): Promise<UserSignInDto> {
  return Api.request({
    url: createUserFetchUrl(ENDPOINTS.signIn),
    method: 'POST',
    body: JSON.stringify({
      login,
      password,
      isAgent,
      grant_Type: 'password',
    }),
    headers: { 'Content-Type': CONTENT_TYPE.JSON },
  })
    .then(Api.statuses)
    .then((data) => Api.json(data));
}

export function signInFunSun(tteAuthId: string): any {
  return Api.request({
    url: createUserFetchUrl(ENDPOINTS.signInFunSun),
    method: 'POST',
    body: JSON.stringify({ tteAuthId: tteAuthId }),
    headers: { 'Content-Type': CONTENT_TYPE.JSON },
  })
    .then(Api.statuses)
    .then(Api.json);
}

export function signInTinkoff(isMobile: boolean): any {
  return Api.request({
    url: createExternalFetchUrl(EXTERNAL_ENDPOINTS.signInTinkoff, {
      isMobile: isMobile,
    }),
    method: 'GET',
    headers: { 'Content-Type': CONTENT_TYPE.JSON },
  })
    .then(Api.statuses)
    .then(Api.json);
}

export function signInTinkoffComplete(payload: {
  state: string;
  code: string;
  sessionState: string;
}): any {
  return Api.request({
    url: createExternalFetchUrl(EXTERNAL_ENDPOINTS.signInTinkoffComplete),
    method: 'POST',
    body: JSON.stringify({
      state: payload.state,
      code: payload.code,
      sessionState: payload.sessionState,
    }),
    headers: { 'Content-Type': CONTENT_TYPE.JSON },
  })
    .then(Api.statuses)
    .then(Api.json);
}

export function signInSamo(Samo: string): any {
  return Api.request({
    url: createUserFetchUrl(ENDPOINTS.signInSamo),
    method: 'POST',
    body: JSON.stringify({ Samo: Samo }),
    headers: { 'Content-Type': CONTENT_TYPE.JSON },
  })
    .then(Api.statuses)
    .then(Api.json);
}

/**
 * Required when not authorized user book tickets
 * action: @booking/create
 * saga: booking / bookFlightSaga -> booking / bookFlightRequest -> signInWithouRedirect
 * @param body
 */
export function simpleSignUp(
  body: UserSimpleSignUpBody,
): Promise<UserSignUpDto> {
  return Api.request({
    url: createUserFetchUrl(ENDPOINTS.simpleSignUp),
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': CONTENT_TYPE.JSON },
  })
    .then(Api.statuses)
    .then((data) => Api.json(data));
}

/**
 * action: @user/signUp
 * saga: signUpSaga
 * @param body
 */
export function signUp(body: UserSignUpBody): Promise<UserSignUpDto> {
  return Api.request({
    url: createUserFetchUrl(ENDPOINTS.signUp),
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': CONTENT_TYPE.JSON },
  })
    .then(Api.statuses)
    .then((data) => Api.json(data));
}

export function RegisterCustomerLoyaltyTTE(
  body: RegisterCustomerLoyaltyTTEPayload,
): any {
  return Api.request({
    url: `https://api.mindbox.ru/v3/operations/sync?endpointId=Tui.FUNSUNTTE&operation=RegisterCustomerLoyaltyTTE`,
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': CONTENT_TYPE.JSON,
      Authorization: 'SecretKey CUUxxAuL3TYlTDiyQZhazVeCOd60P2RD',
    },
  })
    .then(Api.statuses)
    .then((data) => Api.json(data));
}

/**
 * action: @user/signUp
 * saga: signUpSaga
 * @param body
 */
export function passwordChange(
  body: PasswordChangeBody,
): Promise<UserSignUpDto> {
  return Api.request(
    {
      url: createPasswordFetchUrl(ENDPOINTS.passwordRecovery),
      method: 'PUT',
      body: JSON.stringify(body),
      headers: { 'Content-Type': CONTENT_TYPE.JSON },
    },
    Api.STRATEGY_TYPES.AUTH,
  )
    .then(Api.statuses)
    .then((data) => Api.json(data));
}

/**
 * action: @user/signUp
 * saga: signUpSaga
 * @param body
 */
export function forgotPassword(email: string): Promise<UserSignUpDto> {
  return Api.request({
    url: createPasswordFetchUrl(ENDPOINTS.passwordRecovery),
    method: 'POST',
    body: JSON.stringify({ email }),
    headers: { 'Content-Type': CONTENT_TYPE.JSON },
  })
    .then(Api.statuses)
    .then((data) => Api.json(data));
}

export const getUserData = (): Promise<GetUserDataDto> => {
  return Api.request(
    {
      url: createUserFetchUrl(ENDPOINTS.getUserData),
      headers: { 'Content-Type': CONTENT_TYPE.JSON },
    },
    Api.STRATEGY_TYPES.AUTH,
  )
    .then(Api.statuses)
    .then((data) => Api.json(data));
};

export const updateUserData = (data: UpdateUserPayload) => {
  return Api.request(
    {
      url: createUserFetchUrl(ENDPOINTS.updateUserData),
      method: 'PUT',
      headers: { 'Content-Type': CONTENT_TYPE.JSON },
      body: JSON.stringify({ ...data, gender: 'Male' }),
    },
    Api.STRATEGY_TYPES.AUTH,
  )
    .then(Api.statuses)
    .then((data) => Api.json(data));
};

export const getPassangers = (
  SearchText?: string,
): Promise<GetAviaPassengersDto> => {
  return Api.request(
    {
      url: createAviaPassengersFetchUrl(ENDPOINTS.getPassangers, {
        PageSize: 1000,
        SearchText,
      }),
      headers: {
        'Content-Type': Api.CONTENT_TYPE.JSON,
      },
    },
    'AUTH',
  )
    .then(Api.statuses)
    .then(Api.json);
};

export const createPassanger = (data: CreatePassengerBody) =>
  Api.request(
    {
      url: createAviaPassengersFetchUrl(ENDPOINTS.createPassanger),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ...data, correlationId: undefined }),
    },
    'AUTH',
  )
    .then(Api.statuses)
    .then(Api.json);

export const editPassanger = (data: EditPassengerBody) =>
  Api.request(
    {
      url: createAviaPassengersFetchUrl(ENDPOINTS.editPassanger),
      method: 'PUT',
      headers: {
        'Content-Type': Api.CONTENT_TYPE.JSON,
      },
      body: JSON.stringify(data),
    },
    'AUTH',
  )
    .then(Api.statuses)
    .then(Api.json);

export const removePassanger = (data: string) => {
  return Api.request(
    {
      url: createAviaPassengersFetchUrl(ENDPOINTS.editPassanger),
      method: 'DELETE',
      headers: {
        'Content-Type': Api.CONTENT_TYPE.JSON,
      },
      body: JSON.stringify({ customerPassengerId: data }),
    },
    'AUTH',
  ).then(Api.statuses);
};
