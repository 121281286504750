import React from 'react';
import styled from 'styled-components';
import { Text } from '@components/ui';

export const GrayText = styled(Text)`
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.05em;
  color: #7e8389;
  z-index: 2;
`;
export const Title = styled(Text)`
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #2e2e32;
  z-index: 2;
  position: relative;

  @media (max-width: 1169px) {
    font-size: 20px;
    line-height: 26px;
  }
`;
export const Paragraph = styled(Text)`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #2e2e32;

  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 20px;
  }
`;
