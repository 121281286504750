import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { TrainRoute } from '@modules/trainTickets/dto/GetTrainRouteDto';
import styled from 'styled-components';
import { Text } from '@components/ui/Typography';
import { ReactComponent as TrainIcon } from '@assets/media/train-tickets/train-icon.svg';
import { useMediaQuery } from 'react-responsive';

interface Props {
  routeDetails: TrainRoute[]; // Данные о маршруте, полученные от сервера

  segmentType?: 'base' | 'additional'; // Тип сегмента ЖД пути - основной или дополнительный (для беспересадочных маршрутов)
  routeType: 'avia' | 'railway' | 'bus'; // Тип маршрута: авиа, железнодорожный или автобус
  title: string; // Заголовок панели маршрута
  fromStationCode: string; // Код станции отправления
  toStationCode: string; // Код станции прибытия
  additionalOptions?: {
    isRailwayTransit?: boolean; // Является ли маршрут транзитным через СНГ
  };
}

const Wrapper = styled.div``;

const Header = styled.div`
  padding: 10px 20px;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  z-index: 4;
  @media (max-width: 767px) {
    padding: 15px 20px 15px 20px;
  }
`;

const Table = styled.table`
  font-size: 14px;
  width: 100%;
  padding: 4px 20px 0 20px;

  @media (max-width: 767px) {
    font-size: 12px;
  }
`;

const TableHead = styled.th<{ align: 'left' | 'right' | 'center' }>`
  color: #737373;
  background-color: #ffffff;
  text-align: ${({ align }) => align};
  padding-bottom: 20px;
`;

const TableCell = styled.td<{ align: 'left' | 'right' | 'center' }>`
  padding-bottom: 10px;
  text-align: ${({ align }) => align};
`;

const TableCellWithPadding = styled.td<{ align: 'left' | 'right' | 'center' }>`
  padding-bottom: 10px;
  padding-left: 10px;
  text-align: ${({ align }) => align};
  white-space: nowrap;
`;

const TableCellContainer = styled.div`
  display: flex;
  align-items: center;
`;

const TableHeadRow = styled.tr`
  position: sticky;
  top: 50px;
  z-index: 4;
`;

const ExpandButton = styled.div`
  padding: 10px 20px 40px 20px;

  &:hover {
    cursor: pointer;
  }
`;

const Point = styled.div`
  width: 8px;
  height: 8px;
  background-color: #4872f2;
  border-radius: 50%;
  margin-right: 8px;
  position: relative;
  z-index: 2;
`;

const PointFromToStation = styled(Point)`
  border: 4px solid #e3eaff;
  position: relative;
  left: -4px;
  margin-right: 0;
`;

const PointMuted = styled(Point)`
  background-color: #737373;
`;

const Line = styled.div`
  background-color: #e3eaff;
  width: 2px;
  height: 46px;
  left: 3px;
  position: absolute;
  z-index: 1;
`;

const LineMuted = styled(Line)`
  background-color: #dcdcdc;
`;

const PointContainer = styled.div`
  position: relative;
`;

const AttentionPanel = styled.div`
  padding: 0 20px;
`;

const Content = styled.div``;

export default function DetailRoutePanel(props: Props) {
  const {
    title,
    routeDetails,
    fromStationCode,
    toStationCode,
    additionalOptions,
    segmentType,
    routeType,
  } = props;

  if (!routeDetails?.length) return null;

  const [indexes, setIndexes] = useState<number[]>([]);
  const [expanded, setExpanded] = useState(false);
  const [fromIndex, setFromIndex] = useState<number>(0);
  const [toIndex, setToIndex] = useState<number>(0);
  const isMobile = useMediaQuery({ maxWidth: 767 });

  useEffect(() => {
    const list = routeDetails?.map((route) => route.station.code);
    const fromIndex = list?.findIndex((code) => fromStationCode === code);
    const toIndex = list?.findIndex((code) => toStationCode === code);
    setFromIndex(fromIndex);
    setToIndex(toIndex);
  }, [routeDetails]);

  useEffect(() => {
    const indexes: number[] = [];
    if (routeDetails?.length > 0) {
      if (expanded) {
        routeDetails.forEach((route, index) => {
          indexes.push(index);
        });
      } else {
        routeDetails.forEach((route, index) => {
          if (index >= fromIndex && index <= toIndex) {
            indexes.push(index);
          }
        });
      }
    }
    setIndexes(indexes);
  }, [routeDetails, expanded, fromIndex, toIndex]);

  const handleExpand = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded]);

  const firstStation = routeDetails[0];
  const lastStation = routeDetails[routeDetails.length - 1];

  return (
    <Wrapper>
      <Header>
        {segmentType === 'base' && `Основной маршрут: `}
        {segmentType === 'additional' && 'Вагоны: '}
        {firstStation?.station.name_ru} – {lastStation?.station.name_ru}
      </Header>
      <Content>
        {additionalOptions?.isRailwayTransit && (
          <AttentionPanel>
            <Text color={'#F25F61'} fontWeight={'600'}>
              Внимание, поезд следует транзитом через СНГ.
            </Text>
          </AttentionPanel>
        )}
        <Table>
          <TableHeadRow>
            <TableHead align={'left'}>Станция</TableHead>
            <TableHead align={'right'}>
              {isMobile ? 'Приб.' : 'Прибытие'}
            </TableHead>
            <TableHead align={'right'}>
              {isMobile ? 'Остан.' : 'Остановка'}
            </TableHead>
            <TableHead align={'right'}>
              {isMobile ? 'Отпр.' : 'Отправление'}
            </TableHead>
          </TableHeadRow>
          {routeDetails.length > 0 ? (
            routeDetails.map((route, index) => {
              if (!indexes.includes(index) && routeType !== 'bus') return false;
              const needLine = expanded
                ? index !== routeDetails.length - 1
                : index !== toIndex;
              return (
                <tr key={index}>
                  <TableCell align={'left'}>
                    <TableCellContainer>
                      {index < fromIndex || index > toIndex ? (
                        <PointContainer>
                          {needLine ? <LineMuted /> : ''}
                          <PointMuted />
                        </PointContainer>
                      ) : fromStationCode === route.station.code ||
                        toStationCode === route.station.code ? (
                        <PointContainer>
                          {needLine ? <Line /> : ''}
                          <PointFromToStation />
                        </PointContainer>
                      ) : (
                        <PointContainer>
                          {needLine ? <Line /> : ''}
                          <Point />
                        </PointContainer>
                      )}
                      {route.station.name_ru}
                    </TableCellContainer>
                  </TableCell>
                  <TableCellWithPadding align={'right'}>
                    {route.localArrivalDateTime
                      ? moment(route.localArrivalDateTime).format('HH:mm')
                      : ''}
                  </TableCellWithPadding>
                  <TableCellWithPadding align={'right'}>
                    {route.stopDuration ? `${route.stopDuration} мин` : ''}
                  </TableCellWithPadding>
                  <TableCellWithPadding align={'right'}>
                    {route.departureDateTime
                      ? moment(route.localDepartureTime).format('HH:mm')
                      : ''}
                  </TableCellWithPadding>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={4}>Загрузка маршрута...</td>
            </tr>
          )}
        </Table>
      </Content>
      {routeDetails && (
        <ExpandButton onClick={handleExpand}>
          <Text color={'blue'}>
            {expanded
              ? 'Скрыть подробный маршрут'
              : 'Показать подробный маршрут'}
          </Text>
        </ExpandButton>
      )}
    </Wrapper>
  );
}
