import {
  FilterTrainDurationItem,
  PassengerTrainInfo,
  TrainDefaultFilter,
  TrainDirectionType,
  TrainFilterItem,
  TrainFilterPrices,
  TrainTimesItem,
} from '@modules/trainTickets';

export enum BusSearchRequestStatus {
  initial = 'initial',
  failure = 'failure',
  loading = 'loading',
  success = 'success',
  tryToCreateOrder = 'tryToCreateOrder',
}

export interface GetBusesTicketsListDto {
  busesPerRoutes: BusRoute[];
  passengersInfo: PassengerTrainInfo[];
}

export interface BusFilterItem {
  label: string;
  value: string;
  checked: boolean;
}

export interface BusRoute {
  buses: Bus[];
  stationFrom: StationBus;
  stationTo: StationBus;
}

export interface Bus {
  additionalInfo?: any; //no info
  arrivalDateTime: string;
  busModel?: string;
  carrier: string;
  carrierCode: string;
  departureDateTime: string;
  destinationGeoLocation?: any;
  destinationStationAddress: string;
  destinationStationCode: string;
  destinationStationName: string;
  durationInMinutes: number;
  freePlaceQuantity: number;
  fromStation: StationBus;
  index: number;
  isAvailableForBooking: boolean;
  isInterstate: boolean;
  originGeoLocation?: any;
  originStationAddress: string;
  originStationCode: string;
  originStationName: string;
  price: number;
  provider: string;
  raceId: string;
  raceName: string;
  raceNumber: string;
  toStation: StationBus;
  ticketsCount: number;
  passengersInfo?: PassengerBusInfo[];
}

export interface PassengerBusInfo {
  passengerType: string;
  count: number;
}

export interface StationBus {
  code: string;
  country: { id: number; name_ru: string };
  expressCode: string;
  guid: string;
  id: number;
  isActive: boolean;
  nameEn: string;
  nameRu: string;
  region: { id: number; name_ru: string };
  sysCode: string;
}

export interface BusRoute {
  buses: Bus[];
  stationFrom: StationBus;
  stationTo: StationBus;
}

export type Station = {
  name: string;
  code: string;
  checked: boolean;
  raceIds?: string[];
};

export interface BusDefaultFilter {
  carriers: boolean;
  discounts: boolean;
  prices: boolean;
  times: boolean;
  busDurations: boolean;
  busServices: boolean;
  busTypes: boolean;
  stations: boolean;
}

export interface BusFilter {
  carriers: TrainFilterItem[]; // наименование перевозчика
  carriersTickets: string[][];
  discounts?: TrainFilterItem[];

  filterDestinationType: TrainDirectionType;
  isFiltered: boolean; // применён ли фильтр
  prices: TrainFilterPrices; // диапазоны цен по поездам и типам купе
  times: TrainTimesItem[]; // время в пути
  busDurationsList: FilterTrainDurationItem[];

  trainServices: TrainFilterItem[]; // услуги в поезде (или поезд + вагон)
  trainServicesTickets: string[][]; // услуги в поезде (или поезд + вагон)

  stations: {
    label: string;
    stations: Station[];
    tickets: string[];
  }[];
  values: {
    stations: any;
    arrivalDates: number[];
    carriers: { [key: string]: boolean };
    departureDates: number[];
    discounts: { [key: string]: boolean };
    prices: number[];
    busServices: { [key: string]: boolean };

    times: number[];
    busDurations: number[];
  };
  isDefault: BusDefaultFilter;
}

export interface GetBusRoutePayload {
  RaceID: string;
  DepartureDate: string;
  Provider: string;
}

export interface RoutePoint {
  address: string | null;
  arrivalDateTime: string;
  departureDateTime: string | null;
  distance: number;
  geoLocation: { latitude: number; longitude: number } | null;
  name: string;
  stopDurationInMinutes: number | null;
}

export interface BusRaceDetails {
  raceName: string;
  routePoints: RoutePoint[];
}
