import React from 'react';
import styled from 'styled-components';
import { ChipProps } from './types';

const Wrapper = styled.div<{ bgColor: string; textColor?: string }>`
  padding: 4px 8px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  background-color: ${({ bgColor }) => bgColor};
  color: ${({ textColor }) => textColor || '#3c3c3c'};
  border-radius: 8px;
  display: inline-block;
  position: relative;
`;

const Content = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 3px;
  white-space: nowrap;
`;

const Chip: React.FC<ChipProps> = ({
  bgColor,
  label,
  icon,
  adaptiveTextColor,
}) => {
  const getBgColor = () => {
    switch (bgColor) {
      case 'default': {
        return '#f3f5fa';
      }
      case 'green': {
        return '#DAF0A2';
      }
      case 'purple': {
        return '#ECE0FE';
      }
      case 'red': {
        return '#FFD3CA';
      }
      case 'yellow': {
        return '#FFECBC';
      }
      case 'blue': {
        return '#E3EAFF';
      }
      default: {
        return '#f3f5fa';
      }
    }
  };
  const getTextColor = () => {
    switch (bgColor) {
      case 'default': {
        return '#3c3c3c';
      }
      case 'green': {
        return '#3c3c3c';
      }
      case 'purple': {
        return '#3c3c3c';
      }
      case 'red': {
        return '#E73C3E';
      }
      case 'yellow': {
        return '#3c3c3c';
      }

      default: {
        return '#3c3c3c';
      }
    }
  };
  return (
    <Wrapper
      textColor={adaptiveTextColor ? getTextColor() : undefined}
      bgColor={getBgColor()}
    >
      <Content>
        {icon ? icon : null}
        {label}
      </Content>
    </Wrapper>
  );
};

export default Chip;
