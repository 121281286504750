import { getIsMobileState } from './../booking/selectors';
import {
  call,
  all,
  takeLatest,
  takeEvery,
  put,
  select,
  spawn,
  delay,
} from 'typed-redux-saga';
import moment from 'moment';
import {
  setLoadingStatus,
  getOrderByNumber,
  setDetailData,
  getOrdersList,
  setOrdersList,
  payOrderRequest,
  printOrderRequest,
  printOrderSuccess,
  confirmPaymentRequest,
  cancelOrderPositionFailure,
  cancelOrderPositionRequest,
  cancelOrderPositionSuccess,
  getHistoryRequest,
  getHistorySuccess,
  getOrdersListNext,
  updateAddictionalServices,
  addAddictionalService,
  deleteAddictionalService,
  addictionalServiceActionsSuccess,
  purifyAddictionalServices,
  cancelNotification,
  approveNotification,
  setCalculationStatus,
  getCalcutionSuccess,
  refreshOrder,
  getActiveCalculationsSuccess,
  issueTicketsRequest,
  issueTicketsSuccess,
  getOrderPaymentsRequest,
  getOrderPaymentsSuccess,
  setAvailableInsurances,
  addInsurances,
  deleteInsurances,
  getModalErrorText,
  showModalError,
  issueOrderPositionRequest,
  issueOrderPositionSuccess,
  createPaymentRequest,
  createPaymentSuccess,
  createPaymentTinkoffRequest,
  confirmPayment2Request,
  confirmPayment2Success,
  confirmPayment2inProccess,
  promocodeApply,
  promocodeApplySuccess,
  getQrSuccess,
  getQrRequest,
  getAncillaryNew,
  getAncillaryNewSuccess,
  addAncillaryNewSuccess,
  addAncillaryNew,
  clearActiveCalculations,
  setDataWithoutNormalize,
} from './duck';

import { Manager as AppealManager } from '@modules/appeals';
import { getUserDataState } from '@modules/user';
import {
  getOrderReferencesRequestWorker,
  getAppealReferencesRequestWorker,
} from '@modules/references';

import {
  GetOrdersListPayload,
  PrintOrderPayload,
  STATUS,
  PayOrderPayload,
  NotifyOrderPayload,
  CancelOrderPositionPayload,
  GetOrdersListNextPayload,
  AddictionalServiceTypes,
  CreatePaymentEntity,
  GetQRCodeDto,
} from './types';
import {
  getDetailOrder,
  orderListStateSelector,
  getAddictionalServices,
  getAddictionalServicesNew,
  getAddictionalServicesDetails,
} from './selectors';
import * as OrderManager from './Manager';
import * as AppealsManager from '@modules/appeals/Manager';
import { setPage, setPageSize } from './utils';
import { Action } from 'redux-act';
import { setLKLoadingStatus, handleHttpErrors } from '@modules/personalArea';

export function* getNotPaidAddictionalServices({
  orderNumber,
}: {
  orderNumber: number;
}) {
  const data = yield* call(
    OrderManager.getAddictionalServicesByOrderNumber,
    orderNumber,
  );
  yield* put(
    updateAddictionalServices(
      (data.additionalServices || []).map((x) => ({
        ...x,
        isPaid: false,
        checked: false,
        isLoading: false,
        expires: null,
        additionalServiceId: x.id,
      })),
    ),
  );
}

function* getDetailData({
  orderNumber,
  token,
}: {
  orderNumber: number;
  token?: string;
}) {
  try {
    yield* put(setLKLoadingStatus(true));
    yield* put(setLoadingStatus(STATUS.loading));
    const user = yield* select(getUserDataState);
    const data = yield* call(OrderManager.getOrderByNumber, orderNumber, token);
    yield* put(setDataWithoutNormalize(data));
    yield* put(setDetailData(data));
    yield* put(
      updateAddictionalServices(
        data.additionalServices.map((x) => ({
          ...x,
          // @ts-ignore
          additionalServiceId: x.additionalServiceId || x.id,
          checked: x.paymentStatus.code === 'NotPaid',
          isPaid:
            x.paymentStatus.code === 'Paid' ||
            x.paymentStatus.code === 'PaidByCredit',
          isLoading: false,
          expires: moment(x.cancellationTime).add(3, 'hours').format(),
        })),
      ),
    );

    if (data?.backOfficeId && user?.agentId) {
      try {
        const response = yield* call(
          OrderManager.getOrderPayments,
          data?.orderNumber.toString(),
        );
        yield* put(getOrderPaymentsSuccess(response));
      } catch (e) {
        //
      }
    }
    yield* put(setLKLoadingStatus(false));

    yield* put(setLoadingStatus(STATUS.success));
    yield* spawn(getActiveCalculationsWorker, data);
    yield* spawn(
      getAvailableInsurancesWorker,
      data.orderPositions[0].id,
      token,
    );

    // yield* put(getActiveCalculationsRequest());
  } catch (e) {
    console.log(e, 'here we are error');

    yield* put(handleHttpErrors(e));
    yield* put(setLoadingStatus(STATUS.failure));
  }
}

function* getActiveCalculationsWorker(data: any) {
  yield* put(clearActiveCalculations());
  const activeCalculations = yield* call(
    OrderManager.getActiveCalculations,
    data.id,
  );
  yield* put(getActiveCalculationsSuccess(activeCalculations));
}

export function* getAvailableInsurancesWorker(
  positionId: string,
  token?: string,
) {
  try {
    const result = yield* call(
      OrderManager.getAvailableInsurances,
      positionId,
      token,
    );
    yield* put(setAvailableInsurances(result));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }
}

export function* getOrderByNumberWorker({
  payload,
}: Action<{ orderNumber: number; token?: string }>) {
  yield* put(purifyAddictionalServices());
  yield* spawn(getDetailData, payload);
  yield* spawn(getAppealReferencesRequestWorker);
  yield* spawn(getNotPaidAddictionalServices, payload);
}

export function* getCalculationWorker(calculationId: string) {
  yield* put(setCalculationStatus('Loading'));
  try {
    const data = yield* call(
      AppealManager.getCalculationByNumber,
      calculationId,
    );
    yield* put(getCalcutionSuccess(data));
    yield* put(setCalculationStatus('Success'));
  } catch {
    yield* put(setCalculationStatus('Failure'));
  }
}

export function* getOrdersListWorker({
  payload,
}: Action<GetOrdersListPayload>) {
  try {
    yield* put(setLKLoadingStatus(true));
    yield* put(setLoadingStatus(STATUS.loading));

    const params = {
      ...payload,
      page: setPage(payload.page),
      pageSize: setPageSize(payload.pageSize),
      number: payload.number,
    };

    yield* spawn(getOrderReferencesRequestWorker);
    const data = yield* call(OrderManager.getOrdersList, params);
    yield* put(setOrdersList({ ...data, currPage: setPage(payload.page) }));

    yield* put(setLoadingStatus(STATUS.success));
    yield* put(setLKLoadingStatus(false));
  } catch (e) {
    yield* put(setLoadingStatus(STATUS.failure));

    yield* put(handleHttpErrors(e));
  }
}

export function* getOrdersListNextWorker(
  action: Action<GetOrdersListNextPayload>,
) {
  try {
    const { currPage, pageCount, orders } = yield* select(
      orderListStateSelector,
    );
    if (currPage === pageCount - 1) return;
    const params = {
      page: currPage + 1,
      pageSize: 10,
      ...action.payload,
    };

    const data = yield* call(OrderManager.getOrdersList, params);
    yield* put(
      setOrdersList({
        ...data,
        orders: [...(orders || []), ...(data.orders || [])],
        currPage: currPage + 1,
      }),
    );
  } catch (e) {
    yield* put(handleHttpErrors(e));
  }
}

/** Сheck if order can be paid, then redirect to payment page, if success */
function* payOrderRequestWorker(action: Action<PayOrderPayload>) {
  const { payload } = action;

  try {
    const data = yield* call(OrderManager.payOrder, payload);

    if (data.paymentURL) {
      window.history.pushState({}, 'Заказ такойто', window.location.href);
      window.location.replace(data.paymentURL);
    }
  } catch (e) {
    yield* put(handleHttpErrors(e));
  }
}

/** Fetching order receipt */
function* printOrderRequestWorker(action: Action<PrintOrderPayload>) {
  const { payload } = action;

  try {
    const data = yield* call(
      OrderManager.printOrder,
      payload.orderId,
      payload.orderPositionId,
    );
    yield* put(printOrderSuccess(data));
  } catch (e) {
    yield* put(handleHttpErrors(e));
  }
}

/**
 * Send request that payment success, and if it succeed redirect load order detail information
 * @param action
 */
function* confirmPaymentRequestWorker(action: Action<NotifyOrderPayload>) {
  const { payload } = action;

  try {
    yield* put(setLoadingStatus(STATUS.loading));
    console.log('BEFORE1');
    yield* call(OrderManager.notifyPayment, payload);
    console.log('AFTER2');
    yield* delay(10000);

    yield* put(setLoadingStatus(STATUS.success));
  } catch (e) {
    yield* put(handleHttpErrors(e));
    yield* put(setLKLoadingStatus(false));
  }
}

function* confirmPayment2RequestWorker(action: Action<NotifyOrderPayload>) {
  const { payload } = action;
  try {
    yield* put(confirmPayment2inProccess(true));

    yield* put(setLoadingStatus(STATUS.loading));
    console.log('BEFORE');
    yield* call(OrderManager.notifyPayment2, payload, payload.token);
    console.log('AFTER');
    yield* delay(10000);

    yield* put(setLoadingStatus(STATUS.success));
    yield* put(confirmPayment2Success(true));
    yield* put(confirmPayment2inProccess(false));

    const orderData = yield* call(
      OrderManager.getOrderByNumber,
      payload.orderNumber,
    );
    yield* put(setDetailData(orderData));
    // window?.top?.location.reload();
  } catch (e) {
    yield* put(handleHttpErrors(e));
    yield* put(setLKLoadingStatus(false));
  }
}

function* refreshOrderWorker() {
  try {
    const state = yield* select(getDetailOrder);
    if (state.orderNumber) {
      const data = yield* call(
        OrderManager.getOrderByNumber,
        state.orderNumber,
      );
      yield* spawn(getAvailableInsurancesWorker, state.orderPositions[0].id);
      yield* put(setDetailData(data));
      const activeCalculations = yield* call(
        OrderManager.getActiveCalculations,
        data.id,
      );
      yield* put(getActiveCalculationsSuccess(activeCalculations));
    }
  } catch (e) {
    console.log(e, 'refresh order error');
  }
}

function* cancelOrderPositionRequestWorker(
  action: Action<CancelOrderPositionPayload>,
) {
  const { payload } = action;
  try {
    yield* call(OrderManager.cancelOrderPosition, payload);
    yield* call(refreshOrderWorker);
    yield* put(cancelOrderPositionSuccess());
  } catch (e) {
    yield* put(cancelOrderPositionFailure());
    yield* put(handleHttpErrors(e));
  }
}

function* getHistoryRequestWorker() {
  const state = yield* select(getDetailOrder);
  try {
    const data = yield* call(OrderManager.getHistory, state.id);
    yield* put(getHistorySuccess(data.event));
  } catch (e) {
    yield* put(handleHttpErrors(e));
  }
}

function* cancelNotificationWorker({ payload }: Action<string>) {
  try {
    yield* call(AppealsManager.declineSupportTicketCalculation, payload);
    yield* call(refreshOrderWorker);
  } catch (e) {
    yield* put(handleHttpErrors(e));
  }
}

function* approveNotificationWorker({ payload }: Action<string>) {
  try {
    yield* call(AppealsManager.applySupportTicketCalculation, payload);
    yield* call(refreshOrderWorker);
  } catch (e) {
    yield* put(handleHttpErrors(e));
  }
}

function* addAddictionalServiceWorker({
  payload,
}: Action<{
  services: AddictionalServiceTypes[];
  additionalContactNameInfo?: string;
  additionalContactPhoneInfo?: string;
}>) {
  try {
    const { id, contactsInfo } = yield* select(getDetailOrder);
    const data = yield* select(getAddictionalServices);
    const items = data.items
      .filter((x) => payload.services.includes(x.type))
      .map((x) => (x.additionalServiceId ? x.additionalServiceId : x.id));
    const filteredItems = items.filter((c, index) => {
      return items.indexOf(c) === index;
    });
    if (items.length) {
      yield* call(OrderManager.addAddictionalServicer, {
        orderId: id,
        additionalServiceIds: filteredItems,
        additionalContactNameInfo:
          payload.additionalContactNameInfo || contactsInfo.contactName || '',
        additionalContactPhoneInfo:
          payload.additionalContactPhoneInfo || contactsInfo.contactPhone || '',
      });
      yield* call(refreshOrderWorker);
      yield* put(addictionalServiceActionsSuccess(payload.services));
    }
  } catch (e) {
    yield* put(handleHttpErrors(e));
  }
}

function* deleteAddictionalServiceWorker({
  payload,
}: Action<AddictionalServiceTypes[]>) {
  try {
    const { id } = yield* select(getDetailOrder);
    const dataFromDetails = yield* select(getAddictionalServices);
    const items = dataFromDetails.items
      .filter((x) => payload.includes(x.type))
      .map((x) => (x.additionalServiceId ? x.additionalServiceId : x.id));
    const filteredItems = items.filter((c, index) => {
      return items.indexOf(c) === index;
    });

    if (items.length > 0) {
      yield* call(OrderManager.deleteAddictionalService, {
        orderId: id,
        additionalServiceIds: filteredItems,
      });
      yield* call(refreshOrderWorker);
      yield* put(addictionalServiceActionsSuccess(payload));
    }
  } catch (e) {
    yield* put(handleHttpErrors(e));
  }
}

export function* issueTicketsSaga(
  action: Action<{
    orderId: string;
    typePaid: string;
    userId?: string | null;
  }>,
) {
  const { payload } = action;
  try {
    const response = yield* call(
      OrderManager.issueTicketsByCreditOrInvoice,
      payload.orderId,
      payload.typePaid,
      payload?.userId,
    );
    yield* put(issueTicketsSuccess(response));
  } catch (err) {
    yield* put(getModalErrorText(err.message));
    yield* put(showModalError(true));
    //err
  }
}

export function* getOrderPaymentsSaga(
  action: Action<{
    orderNumber: string;
  }>,
) {
  const { payload } = action;
  try {
    const response = yield* call(
      OrderManager.getOrderPayments,
      payload.orderNumber,
    );
    // console.log(response);
    yield* put(getOrderPaymentsSuccess(response));
  } catch (err) {
    //err
  }
}

export function* addInsurancesWorker(action: Action<string[]>) {
  const state = yield* select(getDetailOrder);

  yield* call(
    OrderManager.addInsurance,
    state.orderPositions[0].id,
    action.payload,
  );

  // yield* put(
  //   addPriceDetailItem({
  //     total,
  //     item: {
  //       description: item.product.name,
  //       cost: item.totalRate.value,
  //       type: 'insurance',
  //       quantity: 1,
  //       id: item.product.id,
  //     },
  //   })
  // );

  yield* call(refreshOrderWorker);
}

export function* deleteInsurancesWorker(action: Action<string[]>) {
  const state = yield* select(getDetailOrder);

  yield* call(
    OrderManager.deleteInsurance,
    state.orderPositions[0].id,
    action.payload,
  );
  yield* call(refreshOrderWorker);
}

export function* issueOrderPositionSaga(
  action: Action<{
    orderNumber: number;
    orderPositionId: string;
  }>,
) {
  const { payload } = action;
  try {
    const response = yield* call(
      OrderManager.issueOrderPosition,
      payload.orderNumber,
      payload.orderPositionId,
    );
    // console.log(response);
    yield* put(issueOrderPositionSuccess(response));
    yield* call(refreshOrderWorker);
  } catch (err) {
    //err
  }
}

export function* createPaymentSaga(action: Action<CreatePaymentEntity>) {
  const { payload } = action;

  try {
    const response = yield* call(OrderManager.createPayment, payload);
    yield* put(createPaymentSuccess(response));
  } catch (err) {
    //err
  }
}

export function* createPaymentTinkoffSaga(action: Action<CreatePaymentEntity>) {
  const { payload } = action;
  try {
    const response = yield* call(OrderManager.createPaymentTinkoff, payload);
    yield* put(createPaymentSuccess(response));
  } catch (err) {
    //err
  }
}

export function* getQrSaga(action: Action<GetQRCodeDto>) {
  const { payload } = action;
  try {
    const response = yield* call(OrderManager.getQr, payload);
    yield* put(getQrSuccess(response));
  } catch (err) {
    //err
  }
}

export function* promocodeApplySaga(
  action: Action<{ orderId: string; promocode: string }>,
) {
  const { payload } = action;
  try {
    const response = yield* call(OrderManager.promocodeApply, payload);
    yield* put(promocodeApplySuccess(response.message));
    yield* call(refreshOrderWorker);
  } catch (err) {
    yield* put(promocodeApplySuccess(err.message));

    //err
  }
}

export function* getAncillaryNewSaga(
  action: Action<{ OrderPositionId: string }>,
) {
  const { payload } = action;

  try {
    const response = yield* call(
      OrderManager.getAncillaryNew,
      payload.OrderPositionId,
    );

    yield* put(getAncillaryNewSuccess(response));
  } catch (err) {
    //err
  }
}

export function* addAncillaryNewSaga(action: Action<any>) {
  const { payload } = action;
  try {
    //@ts-ignore
    const response = yield* call(OrderManager.addAncillaryNew, payload);
    yield* put(addAncillaryNewSuccess(response));
    // yield* call(refreshOrderWorker);
  } catch (err) {
    //err
  }
}

export default function* orderFlow() {
  yield* all([
    takeEvery(addAddictionalService.getType(), addAddictionalServiceWorker),
    takeEvery(
      deleteAddictionalService.getType(),
      deleteAddictionalServiceWorker,
    ),
    takeLatest(getOrderByNumber.getType(), getOrderByNumberWorker),
    takeLatest(getOrdersList.getType(), getOrdersListWorker),
    takeLatest(payOrderRequest.getType(), payOrderRequestWorker),
    takeLatest(printOrderRequest.getType(), printOrderRequestWorker),
    takeLatest(confirmPaymentRequest.getType(), confirmPaymentRequestWorker),
    takeLatest(confirmPayment2Request.getType(), confirmPayment2RequestWorker),
    takeLatest(refreshOrder.getType(), refreshOrderWorker),
    takeLatest(
      cancelOrderPositionRequest.getType(),
      cancelOrderPositionRequestWorker,
    ),
    takeLatest(getHistoryRequest.getType(), getHistoryRequestWorker),
    takeLatest(getOrdersListNext.getType(), getOrdersListNextWorker),
    takeLatest(approveNotification.getType(), approveNotificationWorker),
    takeLatest(cancelNotification.getType(), cancelNotificationWorker),
    takeLatest(issueTicketsRequest.getType(), issueTicketsSaga),
    takeLatest(getOrderPaymentsRequest.getType(), getOrderPaymentsSaga),
    takeLatest(addInsurances.getType(), addInsurancesWorker),
    takeLatest(deleteInsurances.getType(), deleteInsurancesWorker),
    takeLatest(issueOrderPositionRequest.getType(), issueOrderPositionSaga),
    takeLatest(createPaymentRequest.getType(), createPaymentSaga),
    takeLatest(createPaymentTinkoffRequest.getType(), createPaymentTinkoffSaga),
    takeLatest(getQrRequest.getType(), getQrSaga),

    takeLatest(promocodeApply.getType(), promocodeApplySaga),
    takeLatest(getAncillaryNew.getType(), getAncillaryNewSaga),
    takeLatest(addAncillaryNew.getType(), addAncillaryNewSaga),
  ]);
}
