import { createAction, createReducer } from 'redux-act';
import { combineReducers } from 'redux';
import {
  UserState,
  SignInPayload,
  SignInSuccessPayload,
  SignUpPayload,
  UserSimpleSignUpBody,
  HttpError,
  PasswordChangePayload,
  UpdateUserPayload,
  UserDataState,
  GetAviaPassengersDto,
  CreatePassengerBody,
  EditPassengerBody,
  RegisterCustomerLoyaltyTTEPayload,
} from './types';
import { UserCookie } from '@services/UserCookies';

export const signInRequest = createAction('@@user/signInRequest');
export const signInSuccess = createAction<SignInSuccessPayload>(
  '@user/signInSuccess',
);
export const signInFailure = createAction<HttpError | null>(
  '@user/signInFailure',
);

export const signInFunSunRequest = createAction<{ tteAuthId: string }>(
  '@@user/signInFunSunRequest',
);
export const signInFunSunSuccess = createAction<any>(
  '@user/signInFunSunSuccess',
);
export const signInFunSunFailure = createAction<HttpError | null>(
  '@user/signInFunSunFailure',
);

export const signInFunTinkoffRequest = createAction<{ isMobile: boolean }>(
  '@@user/signInFunTinkoffRequest',
);
export const signInFunTinkoffSuccess = createAction(
  '@user/signInFunTinkoffSuccess',
);
export const signInFunTinkoffFailure = createAction<HttpError | null>(
  '@user/signInFunTinkoffFailure',
);

export const signInFunTinkoffComplete = createAction<{
  state: string;
  code: string;
  sessionState: string;
}>('@@user/signInFunTinkoffComplete');

export const signInSamoRequest = createAction<{ Samo: string }>(
  '@@user/signInSamoRequest',
);
export const signInSamoSuccess = createAction<any>('@user/signInSamoSuccess');
export const signInSamoFailure = createAction<HttpError | null>(
  '@user/signInSamoFailure',
);

export const signIn = createAction<SignInPayload>('@user/signIn');

export const signUpRequest = createAction('@user/signUpRequest');
export const signUpSuccess = createAction<SignInSuccessPayload>(
  '@user/signUpSuccess',
);
export const signUpFailure = createAction<HttpError | null>(
  '@user/signUpFailure',
);
export const signUp = createAction<SignUpPayload>('@user/signUp');

export const simpleSignUp =
  createAction<UserSimpleSignUpBody>('@user/simpleSignUp');

export const registerCustomerLoyaltyTTE =
  createAction<RegisterCustomerLoyaltyTTEPayload>(
    '@user/registerCustomerLoyaltyTTE',
  );

export const logout = createAction('@user/logout');

export const passwordChangeRequest = createAction<PasswordChangePayload>(
  '@user/passwordChangeRequest',
);
export const passwordChangeSuccess = createAction(
  '@user/passwordChangeSuccess',
);
export const passwordChangeFailure = createAction<HttpError | null>(
  '@user/passwordChangeFailure',
);

export const forgotPasswordRequest = createAction<string>(
  '@user/forgotPasswordRequest',
);
export const forgotPasswordSuccess = createAction(
  '@user/forgotPasswordSuccess',
);
export const forgotPasswordFailure = createAction<HttpError | null>(
  '@user/forgotPasswordFailure',
);

export const getUserDataRequest = createAction(
  '@personal-area/getUserDataRequest',
);
export const getUserDataSuccess = createAction<UpdateUserPayload>(
  '@personal-area/getUserDataSuccess',
);
export const getUserDataFailure = createAction(
  '@personal-area/getUserDataFailure',
);

export const changeUserData = createAction<UpdateUserPayload>(
  '@personal-area/changeUserData',
);
export const changeUserDataSuccess = createAction(
  '@personal-area/changeUserDataSuccess',
);

export const callUserData = createAction('@personal-area/callUserData');

export const checkAuthStatus = createAction('@user/checkAuthStatus');

export const getPassanagersRequest = createAction<{ SearchText?: string }>(
  '@personal-area/getAviaPassangersRequest',
);
export const getPassangersSuccess = createAction<GetAviaPassengersDto>(
  '@personal-area/getAviaPassangersSuccess',
);

export const updatePassangersRequest = createAction<EditPassengerBody>(
  '@personal-area/updateAviaPassangersRequest',
);

export const updatePassangersSuccess = createAction(
  '@personal-area/updateAviaPassangersSuccess',
);

export const createPassangersRequest = createAction<CreatePassengerBody>(
  '@personal-area/createAviaPassangersRequest',
);

export const createPassangersSuccess = createAction(
  '@personal-area/createAviaPassangersSuccess',
);

export const setUserError = createAction<string>('@personal-area/setUserError');

//remove passanger
export const removePassangerRequest = createAction<string>(
  '@personal-area/removeAviaPassangersRequest',
);
export const removePassangerSuccess = createAction(
  '@personal-area/removeAviaPassangersSuccess',
);
export const removePassangerFailure = createAction(
  '@personal-area/removeAviaPassangersFailure',
);

export const setCommissionStatus = createAction<boolean>(
  '@personal-area/setCommissionStatus',
);

export const purifyError = createAction('@user/purifyError');

export const setOnlyTrain = createAction<boolean>('@user/setOnlyTrain');
export const checkIsOnlyTrain = createAction('@user/checkIsOnlyTrain');

export const setOnlyBus = createAction<boolean>('@user/setOnlyBus');
export const checkIsOnlyBus = createAction('@user/checkIsOnlyBus');

const aviaPassengers = createReducer<GetAviaPassengersDto>({}, []);
aviaPassengers.on(getPassanagersRequest, () => []);
aviaPassengers.on(getPassangersSuccess, (_, payload) => payload);

const error = createReducer<string>({}, '');
error.on(setUserError, (_, payload) => payload);

const removeLoading = createReducer({}, false);
removeLoading.on(removePassangerRequest, () => true);
removeLoading.on(removePassangerSuccess, () => false);
removeLoading.on(removePassangerFailure, () => false);

const passangerLoading = createReducer({}, false);
passangerLoading.on(updatePassangersRequest, () => true);
passangerLoading.on(updatePassangersSuccess, () => false);
passangerLoading.on(createPassangersRequest, () => true);
passangerLoading.on(createPassangersSuccess, () => false);
passangerLoading.on(setUserError, () => false);

const status = createReducer<UserState>(
  {},
  {
    isAuthorized: null,
    isFetching: false,
    token: UserCookie.token || '',
    error: null,
    forgetPassword: false,
    passwordChanged: false,
  },
);

status.on(signInRequest, (state) => ({ ...state, isFetching: true }));
status.on(purifyError, (state) => ({ ...state, error: null }));
status.on(signInSuccess, (state, payload) => ({
  ...state,
  ...payload,
  isFetching: false,
  isAuthorized: true,
  error: null,
}));
status.on(signInFailure, (state, error) => ({
  ...state,
  isAuthorized: false,
  isFetching: false,
  error: error,
}));

status.on(signUpRequest, (state) => ({ ...state, isFetching: true }));
status.on(signUpSuccess, (state, payload) => ({
  ...state,
  ...payload,
  isFetching: false,
  isAuthorized: true,
  error: null,
}));
status.on(signUpFailure, (state, error) => ({
  ...state,
  isFetching: false,
  error,
}));

status.on(forgotPasswordSuccess, (state) => ({
  ...state,
  isFetching: false,
  forgetPassword: true,
}));
status.on(forgotPasswordFailure, (state, error) => ({
  ...state,
  isFetching: false,
  forgetPassword: false,
  error,
}));

status.on(changeUserData, (state) => ({
  ...state,
  isFetching: true,
}));
status.on(changeUserDataSuccess, (state) => ({
  ...state,
  isFetching: false,
}));

status.on(passwordChangeRequest, (state) => ({
  ...state,
  isFetching: true,
}));
status.on(passwordChangeSuccess, (state) => ({
  ...state,
  isFetching: false,
  passwordChanged: true,
}));
status.on(passwordChangeFailure, (state, error) => ({
  ...state,
  isFetching: false,
  error,
}));

const userData = createReducer<UserDataState>({}, null);
userData.on(getUserDataRequest, () => null);
userData.on(getUserDataSuccess, (s, payload) => ({ ...payload }) as any);
userData.on(getUserDataFailure, () => null);

const userCommissionStatus = createReducer<boolean>({}, false);
userCommissionStatus.on(
  getUserDataSuccess,
  (_, payload) => !payload.isHideCommission,
);
userCommissionStatus.on(setCommissionStatus, (_, payload) => payload);

const isOnlyTrain = createReducer<boolean>({}, false);
isOnlyTrain.on(setOnlyTrain, (_, payload) => payload);

const isOnlyBus = createReducer<boolean>({}, false);
isOnlyBus.on(setOnlyBus, (_, payload) => payload);

export default combineReducers({
  status,
  data: userData,
  aviaPassengers,
  userCommissionStatus,
  isOnlyTrain,
  isOnlyBus,
});
