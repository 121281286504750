import { GetTrainRouteDto, TrainRoute } from '@modules/trainTickets/dto/GetTrainRouteDto';
import { BusRaceDetails, RoutePoint } from '@modules/busTickets/types';
import { TrainRouteStation } from '@modules/trainTickets';

export function normalizeTicketsList(args: any): any {
  return {
    ...args,
    busesPerRoutes: args.busesPerRoutes.map((x: any) => {
      return {
        ...x,
        buses: x.buses.map((x: any, index: number) => ({ ...x, index })),
      };
    }),
  };
}

export const transformRaceToTrainRouteDto = (busRaceDetails: BusRaceDetails): GetTrainRouteDto => {
  const routePoints = busRaceDetails.routePoints;

  const originStation: TrainRouteStation = {
    city: null,
    code: '',
    country: null,
    desctiption: '',
    id: 0,
    isSuburban: false,
    name_ru: routePoints[0].name,
    name_en: routePoints[0].address || '',
    region: null,
  };

  const arrivalStation: TrainRouteStation = {
    city: null,
    code: '',
    country: null,
    desctiption: '',
    id: 1,
    isSuburban: false,
    name_ru: routePoints[routePoints.length - 1].name,
    name_en: routePoints[routePoints.length - 1].address || '',
    region: null,
  };

  const routes: TrainRoute[] = routePoints.map((point: RoutePoint, index) => {
    return {
      arrivalDateTime: point.arrivalDateTime,
      arrivalTime: point.arrivalDateTime
        ? new Date(point.arrivalDateTime).toLocaleTimeString()
        : '',
      clarification: null,
      departureDateTime: point.departureDateTime,
      departureTime: point.departureDateTime
        ? new Date(point.departureDateTime).toLocaleTimeString()
        : '',
      distanceFromInitialStation: point.distance,
      localArrivalDateTime: null,
      localDepartureTime: null,
      routeStopType: 0, // Укажите нужный тип остановки
      station: {
        city: null,
        code: point.name,
        country: null,
        desctiption: '',
        id: index,
        isSuburban: false,
        name_ru: point.name,
        name_en: point.address || '',
        region: null,
      },
      stopDuration: point.stopDurationInMinutes || 0,
    };
  });

  return {
    originStation,
    arrivalStation,
    routes: [routes],
  };
};
