import React, { useMemo } from 'react';
import Chip from '@components/ui/Chip/Chip';
import { getTrainRoute } from '@modules/trainTickets';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '@modules/index';
import { MainTicketInfo } from '@components/common/RouteDetail/MainTicketInfo';
import { BusForRouteDetail } from '@components/bus/Booking/components/TripDetails/components/RouteDetail';
import { getBusRoute } from '@modules/busTickets';
import { busRouteDetailsSelector } from '@modules/busTickets/selectors';
import { transformRaceToTrainRouteDto } from '@modules/busTickets/normalizers';

interface Props {
  bus: BusForRouteDetail;
  isFastest?: boolean;
  isCheapest?: boolean;
  noFeatures?: boolean;
  noCarServices?: boolean;
  hasRoundTrip?: boolean;

  carsSelectList?(): React.ReactNode;
}

export const BusMainTicketInfo = (props: Props) => {
  const dispatch = useDispatch();
  const { bus } = props;

  const busRouteDetails = useSelector(busRouteDetailsSelector);

  const carrierDisplayNames = useMemo(() => {
    if (bus.carrierDisplayNames.length > 1) {
      return bus.carrierDisplayNames.join(', ');
    } else {
      return bus.carrierDisplayNames[0];
    }
  }, [bus.carrierDisplayNames]);

  const featuresNodes = useMemo(() => {
    const result = [];
    if (props.isFastest) {
      result.push(<Chip bgColor={'green'} label={'Самый быстрый'} />);
    }
    if (props.isCheapest) {
      result.push(<Chip bgColor={'red'} label={'Самый дешёвый'} />);
    }
    if (props.hasRoundTrip) {
      result.push(<Chip bgColor={'red'} label={'-20% на обратный билет'} />);
    }
    return result;
  }, [props.isFastest, props.isCheapest, props.hasRoundTrip]);

  return (
    <MainTicketInfo
      routeType={'bus'}
      title={`Маршрут рейса № ${bus.busNumber || 'нет данных'}`}
      departureDateTimeUtc={bus.departureDateTimeUtc}
      arrivalDateTimeUtc={bus.arrivalDateTimeUtc}
      localDepartureDate={bus.localDepartureDate}
      localArrivalDate={bus.localArrivalDate}
      tripDuration={bus.tripDuration}
      routeDetails={
        busRouteDetails
          ? transformRaceToTrainRouteDto(busRouteDetails)
          : undefined
      }
      features={featuresNodes}
      services={undefined}
      fromStation={{
        code: bus.fromStation.name_ru,
        title: bus.fromStation.name_ru,
        description: bus.fromStation.desctiption || '',
      }}
      toStation={{
        code: bus.toStation.name_ru,
        title: bus.toStation.name_ru,
        description: bus.toStation.desctiption || '',
      }}
      additionalOptions={{
        isRailwayTransit: bus.isTransit,
      }}
      getRoute={() => {
        dispatch(
          getBusRoute({
            Provider: bus.provider || '',
            DepartureDate: bus.departureDateTimeUtc,
            RaceID: bus.raceId,
          }),
        );
      }}
    />
  );
};
