import React from 'react';
import {
  GetTrainRouteDto,
  TrainRoute,
} from '@modules/trainTickets/dto/GetTrainRouteDto';
import { ReactComponent as Buscon } from '@assets/media/buses/bus_route_details.svg';
import { ReactComponent as TrainIcon } from '@assets/media/train-tickets/train-icon.svg';
import styled from 'styled-components';
import DetailRoutePanel from '@components/common/RouteDetail/DetailRoutePanel';
import BusRoutePanel from '@components/common/RouteDetail/BusRoutePanel';

interface Props {
  routeDetails?: GetTrainRouteDto; // Данные о маршруте, полученные от сервера

  routeType: 'avia' | 'railway' | 'bus'; // Тип маршрута: авиа, железнодорожный или автобус
  title: string; // Заголовок панели маршрута
  fromStationCode: string; // Код станции отправления
  toStationCode: string; // Код станции прибытия
  additionalOptions?: {
    isRailwayTransit?: boolean; // Является ли маршрут транзитным через СНГ
  };
}

const Wrapper = styled.div`
  max-height: 80vh;
  overflow-y: auto;
`;

const Header = styled.div`
  padding: 15px 20px 10px 20px;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  background-color: #ffffff;
  z-index: 4;
  @media (max-width: 767px) {
    padding: 15px 20px 15px 20px;
  }
  font-weight: 600;
`;

const Content = styled.div``;

export default function RailwayOrBusDetailRoutesContainer(props: Props) {
  const {
    routeType,
    routeDetails,
    fromStationCode,
    toStationCode,
    title,
    additionalOptions,
  } = props;

  let additionalRouteWithArrivalStation: TrainRoute[];
  let additionalRouteWithArrivalStationIndex: number | undefined;
  let updatedFromToStationCodes: { from: string; to: string }[] | undefined =
    [];

  const arrivalStationNotInBaseRoute =
    routeDetails?.routes?.[0].find(
      (route) => route.station.code === toStationCode,
    ) === undefined;

  if (arrivalStationNotInBaseRoute) {
    // ищем станцию прибытия в дополнительных роутах, сохраняем индекс и роут
    routeDetails?.routes?.forEach((route, index) => {
      console.log({ toStationCode, route });

      if (
        route.find((route) => route.station.code === toStationCode) !==
        undefined
      ) {
        additionalRouteWithArrivalStation = route;
        additionalRouteWithArrivalStationIndex = index;
      }
    });

    // надо переопределить fromStationCode и toStationCode для каждого роута в routeDetails
    // для основного роута fromStationCode = fromStationCode, toStationCode = первая станция из additionalRouteWithArrivalStation
    // для additionalRouteWithArrivalStation fromStationCode = первая станция из additionalRouteWithArrivalStation, toStationCode = toStationCode
    // для остальных роутов fromStationCode= fromStationCode, toStationCode = toStationCode
    updatedFromToStationCodes = routeDetails?.routes.map((route, index) => {
      if (index === 0) {
        return {
          from: fromStationCode,
          to: additionalRouteWithArrivalStation?.[0].station.code,
        };
      } else if (index === additionalRouteWithArrivalStationIndex) {
        return {
          from: additionalRouteWithArrivalStation?.[0].station.code,
          to: toStationCode,
        };
      } else {
        return {
          from: fromStationCode,
          to: toStationCode,
        };
      }
    });
  }

  return (
    <Wrapper>
      <Header>
        {routeType === 'railway' && <TrainIcon />}
        {routeType === 'bus' && <Buscon />}
        {title}
      </Header>
      <Content>
        {routeType === 'railway' && routeDetails?.routes &&
          routeDetails?.routes.length > 0 &&
          routeDetails?.routes?.map((route, index) => {
            // заменяем fromStationCode и toStationCode для каждого роута
            let from = fromStationCode;
            let to = toStationCode;
            if (additionalRouteWithArrivalStationIndex) {
              from = updatedFromToStationCodes?.[index].from || '';
              to = updatedFromToStationCodes?.[index].to || '';
            }

            return (
              <DetailRoutePanel
                key={index}
                routeDetails={route}
                routeType={routeType}
                title={title}
                fromStationCode={from}
                toStationCode={to}
                additionalOptions={additionalOptions}
                segmentType={index === 0 ? 'base' : 'additional'}
              />
            );
          })}
        {routeType === 'bus' && routeDetails?.routes &&
          routeDetails?.routes.length > 0 &&
          routeDetails?.routes?.map((route, index) => {
            // заменяем fromStationCode и toStationCode для каждого роута
            let from = fromStationCode;
            let to = toStationCode;
            if (additionalRouteWithArrivalStationIndex) {
              from = updatedFromToStationCodes?.[index].from || '';
              to = updatedFromToStationCodes?.[index].to || '';
            }

            return (
              <BusRoutePanel
                key={index}
                routeDetails={route}
                title={title}
                fromStationCode={from}
                toStationCode={to}
                segmentType={index === 0 ? 'base' : 'additional'}
              />
            );
          })}
      </Content>
    </Wrapper>
  );
}
