import { Action } from 'redux-act';
import {
  call,
  all,
  takeLatest,
  put,
  select,
  spawn,
  delay,
  race,
  take,
} from 'typed-redux-saga';
import {
  setLoadingStatus,
  getList,
  setList,
  getDetail,
  setMessageList,
  setMessageStatus,
  sendMessage,
  setDetail,
  addAppealRequest,
  addAppealFailure,
  addAppealSuccess,
  getHistoryRequest,
  getHistorySuccess,
  cancelAppealRequest,
  cancelAppealSuccess,
  detailPurify,
  sendMessageSuccess,
  getRefundAmountSuccess,
  getRefundAmountError,
  getRefundAmount,
  setRefunded,
  appealModalHandler,
  setAppealModal,
} from './duck';
import { setLKLoadingStatus, handleHttpErrors } from '@modules/personalArea';
import {
  STATUS,
  GetAppealListPayload,
  AddAppealRequestPayload,
  AppealsListNextAction,
  AddAppealDTO,
} from './types/types';
import * as TicketManager from './Manager';
import {
  getDataForAddTicketBody,
  getDetailOrder,
  RefundAmountPayload,
  setDetailData,
} from '@modules/orders';
import {
  AddTicketRequestBody,
  getAppealsListNext,
  Manager,
} from '@modules/appeals';
import { getAppealDetail } from './selectors';
import moment from 'moment';
import { getAppealReferencesRequestWorker } from '@modules/references';
import { ApplicationState } from '@modules/index';
import { AUTH_MODAL_STATUSES, openAuthModal } from '@modules/ui';
import { getUserStatusState, signInSuccess } from '@modules/user';

/**
 * Create new support ticker
 * @param param0 Action<CreatePayload>
 */
export function* addAppealRequestWorker({
  payload,
}: Action<AddAppealRequestPayload>) {
  try {
    let data: AddAppealDTO;
    if (payload?.orderPositionId) {
      const order = yield* select(getDataForAddTicketBody);

      const body: AddTicketRequestBody = {
        ...payload,
        orderId: order.id,
      };
      data = yield* call(TicketManager.addTicket, body);
    } else if (!payload?.orderPositionId) {
      data = yield* call(TicketManager.addTicket, payload);
    }
    yield* put(addAppealSuccess(data!));
    yield* updateSupportTickets(data!);
  } catch (e) {
    // console.log(e);
    // yield* put(addAppealFailure());
  }
}

function* updateSupportTickets(data: AddAppealDTO) {
  const prevOrderData = yield* select(getDetailOrder);
  const updatedSupportTickets = [
    {
      ...data,
      messagesCount: 0,
      unreadMessagesNumber: 0,
    },
    ...prevOrderData.supportTickets,
  ];
  yield* put(
    setDetailData({
      ...prevOrderData,
      supportTickets: updatedSupportTickets,
    }),
  );
}

export function* getListWorker({
  payload: { page, pageSize, statuses, subjects, dateSorting, number },
}: Action<GetAppealListPayload>) {
  yield* spawn(getAppealReferencesRequestWorker);
  yield* put(setLKLoadingStatus(true));

  try {
    const data = yield* call(TicketManager.getCustomerTickets, {
      page: setPage(page),
      pageSize: setPageSize(pageSize),
      statuses,
      subjects,
      dateSorting,
      number: Number(number),
    });

    yield* put(setList({ ...data, currPage: setPage(page) }));
    yield* put(setLoadingStatus(STATUS.success));
    yield* put(setLKLoadingStatus(false));
  } catch (e) {
    yield* put(handleHttpErrors(e));
  }
}

export function* getAppealsListNextWorker(
  action: Action<AppealsListNextAction>,
) {
  try {
    const { currPage, pageCount, tickets } = yield* select(
      (state: ApplicationState) => state.appeals.list,
    );
    if (currPage === pageCount - 1) return;
    const params = {
      page: currPage + 1,
      pageSize: 10,
      ...action.payload,
    };

    const data = yield* call(TicketManager.getCustomerTickets, params);
    yield* put(
      setList({
        ...data,
        tickets: [...(tickets || []), ...(data.tickets || [])],
        currPage: currPage + 1,
      }),
    );
  } catch (e) {
    yield* put(handleHttpErrors(e));
  }
}

export function* getDetailWorker({ payload }: Action<number>) {
  while (true) {
    yield* put(setLoadingStatus(STATUS.loading));
    try {
      const { messages, ...detail } = yield* call(
        TicketManager.getTicketByNumber,
        payload,
      );

      messages
        .filter((value) => !value.owner && value.status !== 'Read')
        .forEach((value) => {
          TicketManager.setRead(value.id);
        });

      const calculation =
        detail.calculations.length === 0
          ? null
          : {
              ...detail.calculations.sort((a, b) =>
                moment(a.createdDate).diff(moment(b.createdDate)),
              )[detail.calculations.length - 1],
              isLoading: false,
              error: null as null,
            };

      for (const c of detail.calculations) {
        if (!c.isRead) {
          yield* spawn(TicketManager.setCalculationRead, c.id);
        }
      }

      yield* put(
        setDetail({
          ...detail,
          calculation: calculation,
        }),
      );

      yield* put(setMessageList(messages));
      yield* put(setLoadingStatus(STATUS.success));
      yield* put(setLKLoadingStatus(false));
    } catch (e) {
      yield* put(handleHttpErrors(e));
    }
    yield* delay(3000);
  }
}

export function* sendMessageWorker({
  payload,
}: Action<{ message: string; files: File[] }>) {
  try {
    yield* put(setMessageStatus(STATUS.loading));
    const { id, number } = yield* select(getAppealDetail);
    yield* call(TicketManager.sendMessage, payload.message, id, payload.files);
    const { messages } = yield* call(TicketManager.getTicketByNumber, number);
    yield* put(sendMessageSuccess(true));
    yield* put(setMessageList(messages));
    yield* put(setMessageStatus(STATUS.success));
  } catch (e) {
    yield* put(setMessageStatus(STATUS.failure));
    yield* put(handleHttpErrors(e));
  }
}

function* getHistoryRequestWorker() {
  const state = yield* select(getAppealDetail);
  try {
    const data = yield* call(Manager.getHistory, state.id);

    yield* put(getHistorySuccess(data.event));
  } catch (e) {
    yield* put(handleHttpErrors(e));
  }
}

function* cancelAppealRequestWorker({ payload }: Action<string>) {
  try {
    yield* call(Manager.cancelAppeal, payload);
    yield* put(cancelAppealSuccess());
  } catch (e) {
    yield* put(handleHttpErrors(e));
  }
}

function* refundAmountRequestWorker({ payload }: Action<RefundAmountPayload>) {
  try {
    const response = yield* call(TicketManager.getRefundAmountRequest, {
      orderPositionId: payload.orderPositionId,
    });
    yield* put(getRefundAmountSuccess(response));
    const refundedItems = [
      ...(response.refundedTickets || []),
      ...(response.refundedEmds || []),
    ];
    const hasRefundedItems = Boolean(refundedItems.length);
    yield* put(
      setRefunded({ isRefunded: hasRefundedItems, data: refundedItems }),
    );
  } catch (error) {
    yield* put(getRefundAmountError({ error: error.message }));
    console.log(error, 'error');
    yield* put(addAppealFailure());
  }
}

function* toggleAppealModalSaga() {
  try {
    const { isAuthorized } = yield* select(getUserStatusState);
    if (!isAuthorized) {
      yield* put(openAuthModal(AUTH_MODAL_STATUSES.AUTH));
      // Wait for the user to be authorized
      yield* take(signInSuccess);
    }
    yield* put(setAppealModal(true));
  } catch (error) {
    yield* put(handleHttpErrors(error));
  }
}

export default function* appealFlow() {
  yield* all([
    takeLatest(addAppealRequest.getType(), addAppealRequestWorker),
    takeLatest(getList.getType(), getListWorker),
    takeLatest(getDetail.getType(), function* (action) {
      yield* race([
        call(getDetailWorker, action as any),
        take(detailPurify.getType()),
      ]);
    }),
    takeLatest(sendMessage.getType(), sendMessageWorker),
    takeLatest(getHistoryRequest.getType(), getHistoryRequestWorker),
    takeLatest(cancelAppealRequest.getType(), cancelAppealRequestWorker),
    takeLatest(getAppealsListNext.getType(), getAppealsListNextWorker),
    takeLatest(getRefundAmount.getType(), refundAmountRequestWorker),
    takeLatest(appealModalHandler.getType(), toggleAppealModalSaga),
  ]);
}

function setPage(page?: number) {
  return page ? page : 0;
}

function setPageSize(pageSize?: number) {
  return pageSize ? pageSize : 0;
}
