import { call } from 'typed-redux-saga';

interface Props {
  price: number;
}

export function* aviasalesPixelWorkerSuccess(props: Props) {
  const { price } = props;
  const urlParams = new URLSearchParams(window.location.search);
  const clickId = urlParams.get('marker');
  const payload = urlParams.get('payload');

  if (!clickId) return;

  try {
    yield* call(
      fetch,
      `https://cache-pixel.aviasales.ru/?click_id=${clickId}&is_successful=true${payload ? `&payload=${payload}` : ''}&price=${price}&currency=RUB`,
    );
  } catch (error) {
    console.log(error);
  }
}

export function* aviasalesPixelWorkerFail() {
  const urlParams = new URLSearchParams(window.location.search);
  const clickId = urlParams.get('marker');
  const payload = urlParams.get('payload');

  if (!clickId) return;

  try {
    yield* call(
      fetch,
      `https://cache-pixel.aviasales.ru/?click_id=${clickId}&is_successful=false${payload ? `&payload=${payload}` : ''}&currency=RUB`,
    );
  } catch (error) {
    console.log(error);
  }
}
