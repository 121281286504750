// @ts-ignore
global.SC_DISABLE_SPEEDY = true;
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import * as serviceWorker from './serviceWorker';
import smoothscroll from 'smoothscroll-polyfill';
import * as Sentry from '@sentry/react';

/**
 * String.prototype.replaceAll() polyfill
 * https://gomakethings.com/how-to-replace-a-section-of-a-string-with-another-one-with-vanilla-js/
 * @author Chris Ferdinandi
 * @license MIT
 */
if (!String.prototype.replaceAll) {
  String.prototype.replaceAll = function (str: any, newStr: any) {
    // If a regex pattern
    if (
      Object.prototype.toString.call(str).toLowerCase() === '[object regexp]'
    ) {
      return this.replace(str, newStr as any);
    }

    // If a string
    return this.replace(new RegExp(str, 'g'), newStr as any);
  };
}

switch (process.env.REACT_APP_SENTRY_ENV) {
  case 'production':
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY,
      environment: process.env.REACT_APP_SENTRY_ENV,
      tunnel: '/sentry',
      integrations: [
        new Sentry.BrowserTracing(),
        new Sentry.Replay({
          networkDetailAllowUrls: [
            window.location.origin,
            'localhost',
            'https://avia-new.fstravel.com/api/',
          ],
        }),
      ],
      tracesSampleRate: 1.0,
      replaysOnErrorSampleRate: 1.0,
      maxBreadcrumbs: 30,
    });
    break;
  case 'staging':
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY,
      environment: process.env.REACT_APP_SENTRY_ENV,
      integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
      tracesSampleRate: 1.0,
      replaysOnErrorSampleRate: 1.0,
      maxBreadcrumbs: 30,
    });
    break;
  case 'development':
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY,
      environment: process.env.REACT_APP_SENTRY_ENV,
      integrations: [
        new Sentry.BrowserTracing({
          tracePropagationTargets: [
            'localhost',
            'https://sentry-tte.fstravel.com/api/',
          ],
        }),
        new Sentry.Replay(),
      ],
      // integrations: (integrations) => {
      //   console.log(integrations);
      //   return integrations
      //     .filter((integration) => integration.name !== 'CaptureConsole')
      //     .concat([
      //       new Sentry.BrowserTracing({
      //         tracePropagationTargets: [
      //           'localhost',
      //           'https://sentry-tte.fstravel.com/api/',
      //         ],
      //       }),
      //       new Sentry.Replay(),
      //     ]);
      // },
      replaysOnErrorSampleRate: 1.0,
      tracesSampleRate: 1.0,
      maxBreadcrumbs: 30,
    });
    break;
}

// kick off the polyfill!
smoothscroll.polyfill();

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
