import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { Field, PasswordField, Button, Text } from '@components/ui';

import { FormikProps, Form } from 'formik';
import { useSelector } from 'react-redux';
import { getUserStatusState } from '@modules/user';
import { Checkbox } from '@components/ui/form';
import ru from 'react-phone-input-2/lang/ru.json';
import PhoneInput from 'react-phone-input-2';

interface Values {
  email: string;
  password: string;
}

const StyledField = styled(Field)`
  width: 100%;
`;

const StyledPassword = styled(PasswordField)`
  width: 100%;
`;

const Row = styled.div`
  margin-bottom: 32px;
  display: grid;
`;

const ButtonWrapper = styled.div`
  margin-bottom: 32px;
  display: flex;
  justify-content: center;
  & > button {
    width: 100%;
    height: 41px;
  }
  @media (max-width: 767px) {
    margin-bottom: 20px;
  }
`;

const PasswordRow = styled(Row)`
  margin-bottom: 48px;
  position: relative;
`;

const Suggestion = styled(Text)`
  display: block;
  position: absolute;
  background: #edf1fe;
  box-shadow:
    0px 4px 11px rgba(0, 0, 0, 0.12),
    0px 5px 19px rgba(41, 41, 42, 0.04);
  border-radius: 4px;
  padding: 10px;
  box-sizing: border-box;
  width: 237px;

  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #3c3c3c;
  z-index: 1000;
  bottom: calc(100% + 9px);
`;

const CheckboxWrapper = styled.div`
  display: flex;
  margin-bottom: 25px;

  & > :first-child {
    margin-right: 8px;
  }

  @media screen and (max-width: 1023px) {
    margin-bottom: 30px;
  }
`;

const StyledText = styled(Text)`
  @media (max-width: 767px) {
    font-size: 12px;
  }
`;

const PhoneContainer = styled.div`
  margin: 15px 0;
`;

interface Props {
  props: FormikProps<Values>;
  setisPLChecked: Dispatch<SetStateAction<boolean>>;
  isPLChecked: boolean;
  setPhoneValue: Dispatch<SetStateAction<string>>;
}

const DesktopForm: React.FC<Props> = ({
  props,
  setisPLChecked,
  isPLChecked,
  setPhoneValue,
}) => {
  const { setFieldValue, setFieldTouched, errors, submitCount } = props;
  const [visibility, setVisibility] = React.useState(false);

  const { error, isFetching } = useSelector(getUserStatusState);

  const handleEmailChange: React.ChangeEventHandler<HTMLInputElement> = (e) =>
    setFieldValue('email', e.target.value);
  const handlePasswordChange: React.ChangeEventHandler<HTMLInputElement> = (
    e,
  ) => setFieldValue('password', e.target.value);
  const handleEmailBlur: React.FormEventHandler<HTMLInputElement> = (e) =>
    setFieldTouched('email', true);

  const handlePasswordBlur: React.FormEventHandler<HTMLInputElement> = (e) => {
    setVisibility(false);
    setFieldTouched('password', true);
  };

  const handlePhoneChange = (val: any) => {
    setPhoneValue(val);
    return;
  };

  return (
    <Form autoComplete="off">
      <Row>
        <StyledField
          onChange={handleEmailChange}
          onBlur={handleEmailBlur}
          autoComplete="new-password"
          // name="email"
          error={errors.email && submitCount > 0 ? errors.email : undefined}
          placeholder="Email"
        />
      </Row>
      <PasswordRow>
        {visibility && (
          <Suggestion>
            Пароль должен содержать не менее 8 символов, строчные и заглавные
            латинские буквы и цифру.
          </Suggestion>
        )}
        <StyledPassword
          onChange={handlePasswordChange}
          onBlur={handlePasswordBlur}
          onFocus={() => {
            setVisibility(true);
          }}
          autoComplete="new-password"
          // name="password"
          error={
            errors.password && submitCount > 0 ? errors.password : undefined
          }
          placeholder="Пароль"
        />
      </PasswordRow>
      {error && (
        <Text
          style={{ transform: 'translateY(-37px)', display: 'block' }}
          color="red"
        >
          {error.message}
        </Text>
      )}

      {/* <CheckboxWrapper>
        <Checkbox
          type="squar"
          name="offertaWithInsurances"
          onChange={() => setisPLChecked(!isPLChecked)}
        />{' '}
        <StyledText style={{ color: '#737373' }}>
          Cтать участником{' '}
          <a
            href="https://fstravel.com/pravila-ucastiya-v-programme-loyalnosti-funsun-friends"
            target={'_blank'}
            rel="noreferrer"
          >
            программы лояльности
          </a>{' '}
          и получать фаны для оплаты следующих поездок.
        </StyledText>
      </CheckboxWrapper> */}
      {isPLChecked && (
        <PhoneContainer>
          <PhoneInput
            localization={ru}
            countryCodeEditable={false}
            onChange={handlePhoneChange}
            specialLabel={''}
            enableSearch={true}
            country={'ru'}
            searchPlaceholder="Поиск"
            inputStyle={{
              height: '40px',
              width: '418px',
            }}
            containerStyle={{ height: '40px' }}
            inputProps={{
              id: 'contactPhone',
            }}
          />
        </PhoneContainer>
      )}

      <ButtonWrapper>
        <Button disabled={isFetching} htmlType="submit" color="#4872F2">
          Зарегистрироваться
        </Button>
      </ButtonWrapper>
    </Form>
  );
};

export default DesktopForm;
