import {
  call,
  all,
  takeLatest,
  put,
  delay,
  select,
  spawn,
} from 'typed-redux-saga';
import {
  SignInPayload,
  SignUpPayload,
  UserSimpleSignUpBody,
  PasswordChangePayload,
  UpdateUserPayload,
  EditPassengerBody,
  CreatePassengerBody,
  RegisterCustomerLoyaltyTTEPayload,
} from './types';
import { Action } from 'redux-act';
import {
  signIn,
  signInSuccess,
  signInFailure,
  signInRequest,
  signUpSuccess,
  signUpFailure,
  signUpRequest,
  forgotPasswordFailure,
  forgotPasswordSuccess,
  signUp,
  checkAuthStatus,
  logout,
  passwordChangeRequest,
  passwordChangeFailure,
  passwordChangeSuccess,
  forgotPasswordRequest,
  changeUserData,
  changeUserDataSuccess,
  getUserDataFailure,
  getUserDataRequest,
  getUserDataSuccess,
  getPassanagersRequest,
  getPassangersSuccess,
  updatePassangersRequest,
  updatePassangersSuccess,
  removePassangerRequest,
  removePassangerSuccess,
  createPassangersRequest,
  createPassangersSuccess,
  purifyError,
  signInFunSunRequest,
  signInSamoRequest,
  signInFunTinkoffRequest,
  signInFunTinkoffComplete,
  setOnlyTrain,
  checkIsOnlyTrain,
  checkIsOnlyBus,
  setOnlyBus,
  registerCustomerLoyaltyTTE,
} from './duck';

import {
  openAuthModal,
  AUTH_MODAL_STATUSES,
  changeSearchPanelType,
} from '@modules/ui';
import * as Manager from './Manager';
import { NavigationService } from '../../services';
import { showNotification } from '@modules/notification';
import { setLKLoadingStatus, handleHttpErrors } from '@modules/personalArea';

import { getUserDataState } from './selectors';
import moment from 'moment';
import { UserCookie } from '@services/UserCookies';
import { setAgentAuthInformation } from '@modules/agents/agent-finance/duck';
import { ApplicationState } from '@modules/index';

function* errorHandler(err: any) {
  if (typeof err.name === 'number' && err.name >= 400 && err.name < 500) {
    yield* put(signInFailure({ message: err.message, code: err.name }));
  } else if (err.name === 104) {
    yield* put(
      showNotification({
        code: err.name,
        message: 'Ваш аккаунт заблокирован. Обратитесь к администратору.',
      }),
    );
  } else {
    yield* put(showNotification({ code: err.name, message: err.message }));
  }
}

export function* signInWithouRedirect({
  login,
  password,
  remember,
}: SignInPayload) {
  try {
    yield* put(signInRequest());
    const { accessToken, expiresIn, refreshToken } = yield* call(
      Manager.signIn,
      login,
      password,
    );
    UserCookie.save(accessToken, refreshToken, expiresIn, remember);

    yield* put(signInSuccess({ token: accessToken }));
    yield* call(getUserDataRequestWorker);

    return true;
  } catch (err) {
    yield* call(errorHandler, err);
    return false;
  }
}

function* signInWorker({ payload }: Action<SignInPayload>) {
  const { login, password, remember, resolve } = payload;

  try {
    yield* put(signInRequest());
    const { accessToken, expiresIn, refreshToken } = yield* call(
      Manager.signIn,
      login,
      password,
      payload?.isAgent,
    );
    UserCookie.save(accessToken, refreshToken, expiresIn, remember);

    yield* put(signInSuccess({ token: accessToken }));
    const data = yield* call(Manager.getUserData);
    yield* put(getUserDataSuccess(data));
    yield* call(checkIsOnlyTrainOrBusWorker);
    yield* call(getUserDataRequestWorker);
    if (resolve) yield* call(resolve);
  } catch (err) {
    yield* call(errorHandler, err);
    yield* put(signInFailure(err));

    //
  }
}

function* signInFunSunWorker({ payload }: Action<{ tteAuthId: string }>) {
  const { tteAuthId } = payload;
  // const dispatch = useDispatch();
  try {
    const result = yield* call(Manager.signInFunSun, tteAuthId);
    //@ts-ignore

    const { accessToken, expiresIn, refreshToken } = result;
    UserCookie.save(accessToken, refreshToken, expiresIn);
    yield* put(signInSuccess({ token: accessToken }));
    const data = yield* call(Manager.getUserData);
    yield* put(getUserDataSuccess(data));
    yield* call(checkIsOnlyTrainOrBusWorker);
    yield* call(getUserDataRequestWorker);
  } catch (err) {
    console.log(err);
    // yield* put(showNotification({ code: err.name, message: err.message }));
    yield* put(openAuthModal(AUTH_MODAL_STATUSES.AUTH));
    yield* put(signInFailure(err));

    //
  }
}

function* signInSamoWorker({ payload }: Action<{ Samo: string }>) {
  const { Samo } = payload;
  // const dispatch = useDispatch();
  try {
    const result = yield* call(Manager.signInSamo, Samo);
    //@ts-ignore

    const { accessToken, expiresIn, refreshToken } = result;
    UserCookie.save(accessToken, refreshToken, expiresIn);
    yield* put(signInSuccess({ token: accessToken }));
    const data = yield* call(Manager.getUserData);
    yield* put(getUserDataSuccess(data));
    yield* call(checkIsOnlyTrainOrBusWorker);
    yield* call(getUserDataRequestWorker);
  } catch (err) {
    console.log(err);
    // yield* put(showNotification({ code: err.name, message: err.message }));
    yield* put(openAuthModal(AUTH_MODAL_STATUSES.AUTH));
    yield* put(signInFailure(err));

    //
  }
}

function* signInTinkoffWorker({ payload }: Action<{ isMobile: boolean }>) {
  try {
    const result: any = yield* call(Manager.signInTinkoff, payload.isMobile);
    window.location.href = result.initUrl;
  } catch (err) {
    console.log(err);
    yield* put(openAuthModal(AUTH_MODAL_STATUSES.AUTH));
    yield* put(signInFailure(err));
  }
}

function* signInTinkoffCompleteWorker({
  payload,
}: Action<{
  state: string;
  code: string;
  sessionState: string;
}>) {
  try {
    const result: any = yield* call(Manager.signInTinkoffComplete, payload);
    const { accessToken, expiresIn, refreshToken } = result;
    UserCookie.save(accessToken, refreshToken, expiresIn);
    yield* put(signInSuccess({ token: accessToken }));
    const data = yield* call(Manager.getUserData);
    yield* put(getUserDataSuccess(data));
    yield* call(checkIsOnlyTrainOrBusWorker);
    yield* call(getUserDataRequestWorker);
    yield* call(() => {
      const urlFromSession = sessionStorage.getItem('tinkoffRedirectUrl');
      if (urlFromSession) {
        sessionStorage.removeItem('tinkoffRedirectUrl');
        window.location.href = urlFromSession;
      }
    });
  } catch (err) {
    // если автозация через Т не прошла, то надо всё равно редиректнуть пользователя на страницу, с которой он начал авторизацию
    yield* call(() => {
      const urlFromSession = sessionStorage.getItem('tinkoffRedirectUrl');
      if (urlFromSession) {
        sessionStorage.removeItem('tinkoffRedirectUrl');
        window.location.href = urlFromSession;
      }
    });
    yield* put(openAuthModal(AUTH_MODAL_STATUSES.AUTH));
    yield* put(signInFailure(err));
  }
}

function* signUpWorker({ payload }: Action<SignUpPayload>) {
  try {
    yield* put(signUpRequest());
    const { accessToken, expiresIn, refreshToken } = yield* call(
      Manager.signUp,
      payload,
    );
    UserCookie.save(accessToken, refreshToken, expiresIn, true);
    yield* put(signUpSuccess({ token: accessToken }));
    yield* put(openAuthModal(AUTH_MODAL_STATUSES.EMPTY));
    // yield* call(NavigationService.navigate, `/orders`);
    yield* call(getUserDataRequestWorker);
  } catch (err) {
    yield* put(signUpFailure(err));
  }
}

function* registerCustomerLoyaltyTTESaga({
  payload,
}: Action<RegisterCustomerLoyaltyTTEPayload>) {
  console.log(payload, 'payload');
  try {
    const response = yield* call(Manager.RegisterCustomerLoyaltyTTE, payload);

    console.log(response, 'response');
    // yield* call(NavigationService.navigate, `/orders`);
  } catch (err) {
    yield* put(signUpFailure(err));
  }
}
function* checkAuthStatusWorker() {
  try {
    if (UserCookie.expires && moment().isAfter(UserCookie.expires)) {
      const refreshed = yield* call([UserCookie, UserCookie.refresh]);

      if (!refreshed) {
        throw new Error('expired');
      }
    }
    if (!UserCookie.token) {
      throw new Error('not authorized');
    }
    yield* put(signInSuccess({ token: UserCookie.token as string }));
    yield* spawn(loadUserData);
  } catch (err) {
    yield* put(signInFailure(null));
  }
}

export function* simpleSignUpSaga(data: UserSimpleSignUpBody) {
  try {
    yield* put(signUpRequest());
    const { accessToken, expiresIn, refreshToken } = yield* call(
      Manager.simpleSignUp,
      data,
    );
    UserCookie.save(accessToken, refreshToken, expiresIn, true);
    yield* put(signUpSuccess({ token: accessToken as string }));
    return true;
    yield* call(getUserDataRequestWorker);
  } catch (err) {
    yield* put(signInFailure({ message: err.message, code: err.name }));
    return false;
  }
}

export function* logoutWorker() {
  UserCookie.clear();
  yield* put(signInFailure(null));
  yield* put(openAuthModal(AUTH_MODAL_STATUSES.EMPTY));
  yield* call(getUserDataRequestWorker);
}

export function* passwordChangeRequestWorker({
  payload,
}: Action<PasswordChangePayload>) {
  try {
    yield* call(Manager.passwordChange, payload);
    yield* put(passwordChangeSuccess());
  } catch (err) {
    yield* put(passwordChangeFailure({ message: err.message, code: err.name }));
  }
}

export function* forgotPasswordRequestWorker({ payload }: Action<string>) {
  try {
    yield* put(signUpRequest());
    yield* call(Manager.forgotPassword, payload);
    yield* put(forgotPasswordSuccess());
  } catch (err) {
    yield* put(forgotPasswordFailure({ message: err.message, code: err.name }));
  }
}

function* loadUserData() {
  const userData = yield* select(getUserDataState);
  if (!userData) {
    const data = yield* call(Manager.getUserData);
    yield* put(getUserDataSuccess(data));
    yield* call(checkIsOnlyTrainOrBusWorker);

    if (data?.agentId && data.agentSamoCode) {
      yield put(
        setAgentAuthInformation({
          agentId: data.agentId,
          agentSamoCode: data.agentSamoCode,
        }),
      );
    }
  }
}

export function* getUserDataRequestWorker() {
  try {
    yield* put(setLKLoadingStatus(true));
    const data = yield* call(Manager.getUserData);
    if (data?.agentId && data.agentSamoCode) {
      yield put(
        setAgentAuthInformation({
          agentId: data.agentId,
          agentSamoCode: data.agentSamoCode,
        }),
      );
    }
    yield* put(checkIsOnlyTrain());
    yield* put(checkIsOnlyBus());

    yield* put(getUserDataSuccess(data));
    yield* call(checkIsOnlyTrainOrBusWorker);
    yield* put(setLKLoadingStatus(false));
  } catch (err) {
    yield* put(handleHttpErrors(err));
    yield* put(getUserDataFailure());
  }
}

export function* getUserDataRequestBookingWorker() {
  const data = yield* call(Manager.getUserData);
  yield* put(getUserDataSuccess(data));
  yield* call(checkIsOnlyTrainOrBusWorker);
}

export function* changeUserDataWorker({ payload }: Action<UpdateUserPayload>) {
  try {
    yield* call(Manager.updateUserData, payload);
    yield* delay(2000);

    yield* put(getUserDataSuccess(payload));
    yield* put(changeUserDataSuccess());
  } catch (err) {
    yield* put(handleHttpErrors(err));
    //err
  }
}

export function* editPassangerWorker({ payload }: Action<EditPassengerBody>) {
  try {
    yield* call(Manager.editPassanger, payload);
    yield* delay(1000);
    yield* put(updatePassangersSuccess());
    yield* call(getAviaPassengersWorker);
    if (!payload.isBooking) {
      yield* call(NavigationService.navigate, '/profile/passengers');
    }
    yield* put(updatePassangersSuccess());
  } catch (err) {
    // yield* put(
    //   setUserError('Ошибка при редактировании пассажира, проверьте данные')
    // );
  }
}

export function* createPassangerWorker({
  payload,
}: Action<CreatePassengerBody>) {
  try {
    yield* call(Manager.createPassanger, payload);
    yield* delay(1000);
    yield* put(createPassangersSuccess());
    yield* call(getAviaPassengersWorker);
    if (!payload.isBooking) {
      yield* call(NavigationService.navigate, '/profile/passengers');
    }
  } catch (err) {
    // yield* put(setUserError('Ошибка при создании пассажира, проверьте данные'));
    //err
  }
}

export function* removePassangerWorker({ payload }: Action<string>) {
  try {
    yield* call(Manager.removePassanger, payload);
    yield* call(NavigationService.navigate, '/profile/passengers');

    yield* call(getAviaPassengersWorker);
    yield* put(removePassangerSuccess());
  } catch (err) {
    // yield* put(removePassangerFailure());
    //err
  }
}

export function* openAuthModalWorker() {
  yield* put(purifyError());
}

export function* getAviaPassengersWorker(
  action?: Action<{ SearchText?: string }>,
) {
  const data = yield* call(Manager.getPassangers, action?.payload?.SearchText);
  yield* put(getPassangersSuccess(data));
}

export function* checkIsOnlyTrainOrBusWorker() {
  const userData = yield* select((state: ApplicationState) => state.user.data);
  if (process.env.REACT_APP_TRAIN_TEST_USERNAME === userData?.email) {
    yield* put(setOnlyTrain(true));
    yield* put(changeSearchPanelType('train'));
  } else if (process.env.REACT_APP_BUS_TEST_USERNAME === userData?.email) {
    yield* put(setOnlyBus(true));
    yield* put(changeSearchPanelType('bus'));
  } else {
    yield* put(setOnlyTrain(false));
    yield* put(setOnlyBus(false));
  }
}

export default function* userFlow() {
  yield* all([
    takeLatest(openAuthModal.getType(), openAuthModalWorker),
    takeLatest(getPassanagersRequest.getType(), getAviaPassengersWorker),
    takeLatest(updatePassangersRequest.getType(), editPassangerWorker),
    takeLatest(createPassangersRequest.getType(), createPassangerWorker),
    takeLatest(removePassangerRequest.getType(), removePassangerWorker),
    takeLatest(signIn.getType(), signInWorker),
    takeLatest(signInFunSunRequest.getType(), signInFunSunWorker),
    takeLatest(signInFunTinkoffRequest.getType(), signInTinkoffWorker),
    takeLatest(signInFunTinkoffComplete.getType(), signInTinkoffCompleteWorker),
    takeLatest(signInSamoRequest.getType(), signInSamoWorker),
    takeLatest(signUp.getType(), signUpWorker),
    takeLatest(checkAuthStatus.getType(), checkAuthStatusWorker),
    takeLatest(logout.getType(), logoutWorker),
    takeLatest(passwordChangeRequest.getType(), passwordChangeRequestWorker),
    takeLatest(forgotPasswordRequest.getType(), forgotPasswordRequestWorker),
    takeLatest(getUserDataRequest.getType(), getUserDataRequestWorker),
    takeLatest(changeUserData.getType(), changeUserDataWorker),
    takeLatest(
      registerCustomerLoyaltyTTE.getType(),
      registerCustomerLoyaltyTTESaga,
    ),
  ]);
}
