import { v4 as uuid } from 'uuid';
import {
  FlightSearchDTO,
  GroupFlights,
  FlightListItems,
  FlightListItemFlights,
  Airline,
  ComplexPrice,
  NormalizeItem,
} from './types';
import { uniq } from 'lodash';

export function normalizeSearchResponse(
  response: FlightSearchDTO,
  solo: boolean = false,
) {
  const group: NormalizeItem[] = [];
  const absentCodes = new Set<string>();

  const airlines: { [key: string]: Airline } = {};
  response.references.Airlines.map(
    (airline) => (airlines[airline.code] = airline),
  );

  response.data.forEach((item) => {
    const feature = item.features.find((feature) => feature.type === 'Baggage');
    let baggage = feature
      ? { value: feature.value, content: feature.content }
      : null;

    // check all item.groups[].flights[].segments[].luggage
    // if had different luggage, then sen baggage to { value: -10, content: 'Смешанный багаж. Норма багажа отличается на разных маршрутах.' }
    // luggage is object, so we need to compare it by JSON.stringify
    const luggage = item.groups
      .map((group) =>
        group.flights.map((flight) =>
          flight.segments.map((segment) => segment.luggage),
        ),
      )
      .flat(2);
    const isDifferentLuggage =
      luggage.length > 1 &&
      luggage.some((x, i, arr) => JSON.stringify(x) !== JSON.stringify(arr[0]));
    if (isDifferentLuggage) {
      baggage = {
        value: -10,
        content:
          'Смешанный багаж. Норма багажа отличается на разных маршрутах.',
      };
    }

    if (item.groups.length === 1) {
      group.push({
        airGds: item.airGds,
        groupIndex: item.index,
        flights: item.groups[0].flights,
        prices: item.prices,
        flight_Type: item.flight_Type,
        complexPrices: item.complexPrices,
        isExchangeable: item.isExchangeable,
        funBonus: item.funBonus,
        isRefundable: item.isRefundable,
        features: item.features,
        hasConnectingFlights: item.hasConnectingFlights,
        baggage,
      });
    } else {
      item.groups[0].flights.forEach((itm) => {
        item.groups[1].flights.forEach((itm2) => {
          group.push({
            airGds: item.airGds,
            groupIndex: item.index,
            flights: [itm, itm2],
            prices: item.prices,
            flight_Type: item.flight_Type,
            complexPrices: item.complexPrices,
            isExchangeable: item.isExchangeable,
            funBonus: item.funBonus,

            isRefundable: item.isRefundable,
            baggage,
            hasConnectingFlights: item.hasConnectingFlights,
            features: item.features,
          });
        });
      });
    }
  });

  const tickets: FlightListItems = [];
  group.forEach((group) => {
    const airlinesInfo = uniq(
      group.flights.reduce(
        (acc, x) => [
          ...acc,
          ...x.segments.map((segment) => airlines[segment.airlineCode]),
        ],
        [] as Airline[],
      ),
    );
    if (solo) {
      group.flights.forEach((flight) => {
        const from = flight.segments[0].from;
        const to = flight.segments[flight.segments.length - 1].to;
        const isLowcoster = flight.segments[0].isLowcoster;

        tickets.push({
          airGds: group.airGds,
          complexPrices: group.complexPrices,
          features: group.features,
          airlinesInfo,
          id: uuid(),
          flight_Type: group.flight_Type,
          groupIndex: group.groupIndex,
          isExchangeable: group.isExchangeable,
          hasConnectingFlights: group.hasConnectingFlights,
          isRefundable: group.isRefundable,
          funBonus: group.funBonus,

          _isLowcoster: isLowcoster,

          flights: [
            {
              index: flight.index,
              stops: flight.stops,
              duration: flight.duration,
              from,
              to,
              segments: flight.segments.map((segment) => {
                if (!airlines[segment.operatingAirlineCode]) {
                  absentCodes.add(segment.operatingAirlineCode);
                }
                return {
                  ...segment,
                  airlineInfo: {
                    ...airlines[segment.airlineCode],
                    operatingAirlineCode: segment.operatingAirlineCode,
                    operatingAirlineName:
                      airlines[segment.operatingAirlineCode]?.name ||
                      'нету в референсах',
                  },
                };
              }),
            },
          ],
          prices: group.prices,
          baggage: group.baggage,
          searchId: response.responseId || '',
        });
      });
    } else {
      const flights: FlightListItemFlights = [];
      const isLowcoster = group.flights[0]?.segments[0]?.isLowcoster;

      group.flights.forEach((flight) => {
        const from = flight.segments[0].from;
        const to = flight.segments[flight.segments.length - 1].to;

        flights.push({
          index: flight.index,
          stops: flight.stops,
          duration: flight.duration,
          from,
          to,
          segments: flight.segments.map((segment) => {
            return {
              ...segment,
              airlineInfo: {
                ...airlines[segment.airlineCode],
                operatingAirlineCode: segment.operatingAirlineCode,
                operatingAirlineName:
                  airlines[segment.operatingAirlineCode]?.name,
              },
            };
          }),
        });
      });

      tickets.push({
        airGds: group.airGds,
        complexPrices: group.complexPrices,
        features: group.features,
        flight_Type: group.flight_Type,
        airlinesInfo,
        groupIndex: group.groupIndex,
        id: uuid(),
        flights: flights,
        isExchangeable: group.isExchangeable,
        isRefundable: group.isRefundable,
        funBonus: group.funBonus,

        _isLowcoster: isLowcoster,
        hasConnectingFlights: group.hasConnectingFlights,

        prices: group.prices,
        baggage: group.baggage,
        searchId: response.responseId || '',
      });
    }
  });

  if (absentCodes.size > 0) {
    console.log(
      '%c отсутствуют авиакомпании в референсах: ' +
        Array.from(absentCodes).join(','),
      'color:yellow;padding: 1rem;background: linear-gradient( green, orangered);',
    );
  }
  return {
    ...response,
    flightsList: {
      items: tickets,
      notFilteredItems: tickets,
    },
  };
}
